import PrimeReactModule from "../PrimeReact";

const SummarySkeleton = () => {
  return (
    <div className="p-3">
      <PrimeReactModule.Skeleton
        width="full"
        className="mb-2"
      ></PrimeReactModule.Skeleton>
      <PrimeReactModule.Skeleton
        width="full"
        className="mb-2"
      ></PrimeReactModule.Skeleton>
      <PrimeReactModule.Skeleton
        width="full"
        className="mb-2"
      ></PrimeReactModule.Skeleton>
      <PrimeReactModule.Skeleton
        width="full"
        className="mb-2"
      ></PrimeReactModule.Skeleton>
      <PrimeReactModule.Skeleton
        width="full"
        className="mb-2"
      ></PrimeReactModule.Skeleton>
    </div>
  );
};

export default SummarySkeleton;