import React, { useMemo } from 'react';
import { createStore, Plugin, PluginFunctions } from '@react-pdf-viewer/core';
import ReadingIndicator from '../ReadingIndicator';

interface StoreProps {
    getPagesContainer?(): HTMLElement;
}

interface ReadingIndicatorPlugin extends Plugin {
    ReadingIndicator: () => React.ReactElement;
}

const ReadingIndicatorPluginComp = (): ReadingIndicatorPlugin => {
    const store = useMemo(() => createStore<StoreProps>({}), []);

    const ReadingIndicatorDecorator = () => <ReadingIndicator store={store} />;

    return {
        install: (pluginFunctions: PluginFunctions) => {
            store.update('getPagesContainer', pluginFunctions.getPagesContainer);
        },
        ReadingIndicator: ReadingIndicatorDecorator,
    };
};

export default ReadingIndicatorPluginComp;