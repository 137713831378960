import { forwardRef } from "react";
import Form from "./Form";

const Module = (props: any, ref: any) => {
  const { dataSet, setDataSet, prog, show, onTriggerEvent, permission } = props;

  return (
    <div className="w-full p-2 mt-2 border-top-1 surface-border">
      {dataSet && (
        <>
          {dataSet?.length > 0 &&
            <>
              <Form prog={prog} show={show} onTriggerEvent={onTriggerEvent} dataSet={dataSet} ref={ref} setDataSet={setDataSet} permission={permission} />
            </>
          }
        </>
      )}
    </div>
  );
}

export default forwardRef(Module);
