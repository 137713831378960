import { useEffect, useState } from "react";
import { http } from "../../../../services/http.service";
import PrimeReactModule from "../../../../utils/context/PrimeReact";
import { Dialog } from "./../../../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { classNames } from "primereact/utils";

const Field = ({ show }: any) => {
  const [formData, setFormData] = useState<any>({
    count: null,
    totalCount: null,
    prompt: "",
    checked: false,
    field: "",
    keyword: "",
  });

  const [updateFormData, setUpdateFormData] = useState<any>({
    updateCount: null,
    updateTotalCount: null,
    updateFieldName: null,
  });

  const [visible, setVisible] = useState<any>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  const [selectedSection, setSelectedSection] = useState<any>();
  const [updateVisible, setUpdateVisible] = useState<any>(false);
  const [fieldId, setFieldId] = useState<any>();
  const [tempId, settempId] = useState<any>();
  const [secId, setSecId] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [secName, setSecName] = useState<any>();
  const [getTemp, setgetTemp] = useState<any>([]);
  const [getForm, setgetForm] = useState<any>([]);
  const [getFiled, setGetFiled] = useState<any>([]);

  useEffect(() => {
    getTemplate();
  }, []);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    resetForm,
  }: any = useFormik({
    initialValues: {
      count: null,
      field: "",
      totalCount: null,
      keyword: "",
      prompt: "",
    },
    validationSchema: Yup.object({
      count: Yup.number().required("Count is required"),
      field: Yup.string().required("Field is required"),
      totalCount: Yup.number().required("Total Count is required"),
      keyword: Yup.string().required("Keyword is required"),
      prompt: Yup.string().required("Prompt is required"),
    }),
    onSubmit: async (data: any) => {
      var body: any = {
        field: data.field,
        count: data.count,
        totalCount: data.totalCount,
        sectionId: secId,
        templateId: tempId,
        keyword: data.keyword.replace(/\s+/g, ""),
        status: formData.checked,
        prompt: data.prompt,
      };
      http.post("field/prompt/post", body).then((res: any) => {
        if (res?.code === 201) {
          show("Success", res.data, "success");
          getField("");
          resetForm();
          setVisible(false);
        }
      });
    },
  });

  const getTemplate = () => {
    http.get("template/data").then((res: any) => {
      if (res) {
        setgetTemp(res?.data);
      }
    });
  };

  const templateChange = (e: any) => {
    var tempplateid = e ? e.value.templateId : tempId;
    http.get("section/get/" + tempplateid).then((res: any) => {
      if (res?.code === 200) {
        setgetForm(res.data);
      }
    });
  };

  const footerContent = (
    <div>
      <PrimeReactModule.Button
        label="Cancel"
        onClick={() => {
          setVisible(false);
          resetForm();
        }}
        className="p-button-secondary"
      />
      <PrimeReactModule.Button
        label="Add"
        disabled={!isValid}
        onClick={() => handleSubmit()}
        autoFocus
      />
    </div>
  );

  const getField = (e: any) => {
    var sectionId = e ? e.value.sectionId : secId;
    http.get("field/get/" + tempId + "/" + sectionId).then((res: any) => {
      if (res.code === 200) {
        setGetFiled(res.data);
      }
    });
  };

  const updateFooterContent = (
    <div>
      <PrimeReactModule.Button
        label="Cancel"
        onClick={() => setUpdateVisible(false)}
        className="p-button-secondary"
      />
      <PrimeReactModule.Button
        label="Update"
        onClick={() => UpdateTemplate()}
        autoFocus
      />
    </div>
  );

  const UpdateTemplate = () => {
    var body: any = {
      field: updateFormData.updateFieldName,
      totalCount: updateFormData.updateTotalCount,
      count: updateFormData.updateCount,
    };
    http.put("field/update/" + fieldId, body).then((res: any) => {
      if (res?.code === 200) {
        show("Success", res.data, "success");
        getField("");
        setUpdateVisible(false);
      }
    });
  };

  const updateTempBody = (options: any) => {
    return (
      <>
        <div>
          <PrimeReactModule.Button
            icon="pi pi-pencil"
            tooltip="Edit"
            tooltipOptions={{ position: "top" }}
            className="text-green-600 bg-white border-none"
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={() => rowEdit(options)}
          ></PrimeReactModule.Button>
          <PrimeReactModule.Button
            icon="pi pi-trash"
            tooltip="Delete"
            tooltipOptions={{ position: "top" }}
            className="text-red-600 bg-white border-none"
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={() => deleteSec(options)}
          ></PrimeReactModule.Button>
        </div>
      </>
    );
  };

  const deleteSec = (options: any) => {
    http.delete("field/update/" + options.fieldId).then((res: any) => {
      if (res.code === 200) {
        getField("");
        show(res.message, res.data, "success");
      }
    });
  };
  const rowEdit = (options: any) => {
    setUpdateFormData({
      ...updateFormData,
      updateCount: options.fieldNo,
      updateFieldName: options.fieldName,
      updateTotalCount: options.fieldCount,
    });
    setFieldId(options.fieldId);
    setUpdateVisible(true);
  };
  return (
    <>
      <div className="grid m-0">
        <div className="col-12 md:col-4">
          <label htmlFor="Template">Template</label>
          <PrimeReactModule.Dropdown
            value={selectedTemplate}
            onChange={(e) => {
              settempId(e?.value?.templateId);
              templateChange(e);
              setSelectedTemplate(e?.value);
              setSelectedSection("");
              setGetFiled([]);
            }}
            className="w-full"
            filter
            name="fieldId"
            options={getTemp}
            optionLabel="templateName"
            filterBy="templateName"
            placeholder="Select a Template"
          />
        </div>
        <div className="col-12 md:col-4">
          <label htmlFor="Template">Section</label>
          <PrimeReactModule.Dropdown
            value={selectedSection}
            onChange={(e) => {
              setSecId(e?.value?.sectionId);
              setSecName(e?.value?.sectionName);
              getField(e);
              setSelectedSection(e?.value);
            }}
            className="w-full"
            filter
            name="templateId"
            options={getForm}
            optionLabel="sectionName"
            filterBy="sectionName"
            placeholder="Select a Template"
          />
        </div>
        <div className="col-12 md:col-4 text-right mt-4">
          <PrimeReactModule.Button
            label="Add"
            disabled={selectedSection ? false : true}
            onClick={() => setVisible(true)}
          />
        </div>
        <div className="col-12">
          <PrimeReactModule.DataTable
          scrollable
          scrollHeight="calc(100vh - 425px)"
            size="small"
            showGridlines
            rows={25}
            responsiveLayout="scroll"
            rowsPerPageOptions={[5, 10, 25, 50]}
            value={getFiled}
            editMode="row"
            dataKey="fieldId"
            tableStyle={{ minWidth: "50rem" }}
            globalFilterFields={["fieldName", "prompt"]}
            emptyMessage="No Field's Found."
          >
            <PrimeReactModule.Column
              sortable
              field="fieldId"
              header="Clause Id"
              style={{ width: "20%" }}
            ></PrimeReactModule.Column>
            <PrimeReactModule.Column
              sortable
              field="fieldName"
              header="Clause Name"
              style={{ width: "20%" }}
            ></PrimeReactModule.Column>
            <PrimeReactModule.Column
              sortable
              field="fieldCount"
              header="Total Count "
              style={{ width: "20%" }}
            ></PrimeReactModule.Column>
            <PrimeReactModule.Column
              sortable
              field="fieldNo"
              header="Count"
              style={{ width: "20%" }}
            ></PrimeReactModule.Column>
            <PrimeReactModule.Column
              header="Action"
              body={updateTempBody}
              className="text-center"
              style={{ width: "20%" }}
              align={"center"}
            ></PrimeReactModule.Column>
          </PrimeReactModule.DataTable>
        </div>
      </div>
      <Dialog
        maximizable={false}
        headername={"Add"}
        visible={visible}
        footer={footerContent}
        setVisible={() => setVisible(false)}
      >
        <div className="formgrid grid">
          <div className="field col-12 md:col-6">
            <label htmlFor="name">Count <span className="text-red-700"> *</span></label>
            <PrimeReactModule.InputNumber
              id="name"
              name="count"
              value={values.count}
              onBlur={handleBlur}
              onChange={(e) =>
                handleChange({ target: { name: "count", value: e.value } })
              }
              className={`mt-1 w-full ${classNames({
                "p-invalid": touched.count && errors.count,
              })}`}
            />
            {errors.count && touched.count && (
              <small className="p-error text-xs">{errors.count}</small>
            )}
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="name">Total Count <span className="text-red-700"> *</span></label>
            <PrimeReactModule.InputNumber 
              id="name"
              name="totalCount"
              value={values.totalCount}
              onBlur={handleBlur}
              onChange={(e) =>
                handleChange({ target: { name: "totalCount", value: e.value } })
              }
              className={`mt-1 w-full ${classNames({
                "p-invalid": touched.totalCount && errors.totalCount,
              })}`}
            />
            {errors.totalCount && touched.totalCount && (
              <small className="p-error text-xs">{errors.totalCount}</small>
            )}
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="name">Field <span className="text-red-700"> *</span></label>
            <PrimeReactModule.InputText
              id="name"
              name="field"
              autoComplete="off"
              value={values.field}
              onBlur={handleBlur}
              onChange={handleChange}
              className={`mt-1 w-full ${classNames({
                "p-invalid": touched.field && errors.field,
              })}`}
            />
            {errors.field && touched.field && (
              <small className="p-error text-xs">{errors.field}</small>
            )}
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="city">Status</label>
            <PrimeReactModule.Checkbox
              className="pt-5"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  checked: e.checked,
                })
              }
              checked={formData.checked}
            ></PrimeReactModule.Checkbox>
          </div>
          <div className="field col-12 md:col-12">
            <label htmlFor="city">Prompt <span className="text-red-700"> *</span></label>
            <PrimeReactModule.InputTextarea
              id="name"
              disabled={!formData.checked}
              name="prompt"
              value={values.prompt}
              onBlur={handleBlur}
              onChange={handleChange}
              className={`mt-1 w-full ${classNames({
                "p-invalid": touched.prompt && errors.prompt,
              })}`}
            />
            {errors.prompt && touched.prompt && (
              <small className="p-error text-xs">{errors.prompt}</small>
            )}
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="name">Keyword <span className="text-red-700"> *</span></label>
            <PrimeReactModule.InputText
              id="name"
              name="keyword"
              value={values.keyword}
              onBlur={handleBlur}
              onChange={handleChange}
              className={`mt-1 w-full ${classNames({
                "p-invalid": touched.keyword && errors.keyword,
              })}`}
            />
            {errors.keyword && touched.keyword && (
              <small className="p-error text-xs">{errors.keyword}</small>
            )}
          </div>
        </div>
      </Dialog>
      <Dialog
        maximizable={false}
        headername={"Update"}
        visible={updateVisible}
        footer={updateFooterContent}
        setVisible={() => setUpdateVisible(false)}
      >
        <div className="formgrid grid">
          <div className="field col-12 md:col-6">
            <label htmlFor="name">Field</label>
            <PrimeReactModule.InputText
              id="name"
              name="name"
              autoComplete="off"
              value={updateFormData.updateFieldName}
              onChange={(e) =>
                setUpdateFormData({
                  ...updateFormData,
                  updateFieldName: e.target.value,
                })
              }
              className="w-full"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="name">Count</label>
            <PrimeReactModule.InputNumber
              id="name"
              name="name"
              value={updateFormData.updateCount}
              onChange={(e) =>
                setUpdateFormData({
                  ...updateFormData,
                  updateCount: e.value,
                })
              }
              className="w-full"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="name">TotalCount</label>
            <PrimeReactModule.InputNumber
              id="name"
              name="name"
              value={updateFormData.updateTotalCount}
              onChange={(e) =>
                setUpdateFormData({
                  ...updateFormData,
                  updateTotalCount: e.value,
                })
              }
              className="w-full"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Field;
