import { useEffect, useState } from "react";
import { http } from "../../../../services/http.service";
import PrimeReactModule from "../../../../utils/context/PrimeReact";
import { Dialog } from "./../../../../components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { classNames } from "primereact/utils";
const Form = ({ show }: any) => {
  const [visible, setVisible] = useState<any>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  const [updateVisible, setUpdateVisible] = useState<any>(false);
  const [updateSecNum, setUpdateSecNum] = useState<any>();
  const [updateSecName, setUpdateSecName] = useState<any>();
  const [tempId, settempId] = useState<any>();
  const [secId, setSecId] = useState<any>();
  const [getTemp, setgetTemp] = useState<any>([]);
  const [getForm, setgetForm] = useState<any>([]);

  useEffect(() => {
    getTemplate();
  }, []);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    resetForm,
  }: any = useFormik({
    initialValues: {
      sectionNum: null,
      SectionName: "",
    },
    validationSchema: Yup.object({
      sectionNum: Yup.number().required("Section Number is required"),
      SectionName: Yup.string().required("Section Name is required"),
    }),
    onSubmit: async (data: any) => {
      var body: any = {
        sectionName: data.SectionName,
        sNo: Number(data.sectionNum),
        templateId: tempId,
      };
      http.post("section/data", body).then((res: any) => {
        if (res?.code === 201) {
          show("Success", res.data, "success");
          templateChange("");
          setVisible(false);
          resetForm();
        }
      });
    },
  });

  const getTemplate = () => {
    http.get("template/data").then((res: any) => {
      if (res) {
        setgetTemp(res?.data);
      }
    });
  };

  const templateChange = (e: any) => {
    var tempplateid = e ? e.value.templateId : tempId;
    http.get("section/get/" + tempplateid).then((res: any) => {
      if (res?.code === 200) {
        setgetForm(res.data);
      }
    });
  };
  const footerContent = (
    <div>
      <PrimeReactModule.Button
        label="Cancel"
        onClick={() => {
          setVisible(false);
          resetForm();
        }}
        className="p-button-secondary"
      />
      <PrimeReactModule.Button
        label="Add"
        disabled={!isValid}
        onClick={() => handleSubmit()}
        autoFocus
      />
    </div>
  );

  const updateFooterContent = (
    <div>
      <PrimeReactModule.Button
        label="Cancel"
        onClick={() => setUpdateVisible(false)}
        className="p-button-secondary"
      />
      <PrimeReactModule.Button
        label="Update"
        onClick={() => UpdateTemplate()}
        autoFocus
      />
    </div>
  );

  const UpdateTemplate = () => {
    var body: any = {
      sectionName: updateSecName,
      sNo: updateSecNum,
      templateId: tempId,
    };
    http.put("section/update/" + secId, body).then((res: any) => {
      if (res?.code === 200) {
        show("Success", res.data, "success");
        templateChange("");
        setUpdateVisible(false);
      }
    });
  };

  const updateTempBody = (options: any) => {
    return (
      <>
        <div>
          <PrimeReactModule.Button
            icon="pi pi-pencil"
            tooltip="Edit"
            tooltipOptions={{ position: "top" }}
            className="text-green-600 bg-white border-none"
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={() => rowEdit(options)}
          ></PrimeReactModule.Button>
          <PrimeReactModule.Button
            icon="pi pi-trash"
            tooltip="Delete"
            tooltipOptions={{ position: "top" }}
            className="text-red-600 bg-white border-none"
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={() => deleteSec(options)}
          ></PrimeReactModule.Button>
        </div>
      </>
    );
  };

  const deleteSec = (options: any) => {
    http.delete("section/update/" + options.sectionId).then((res: any) => {
      if (res.code === 200) {
        templateChange("");
        show(res.message, res.data, "success");
      }
    });
  };

  const rowEdit = (options: any) => {
    setUpdateSecName(options.sectionName);
    setUpdateSecNum(options.sNo);
    setSecId(options.sectionId);
    setUpdateVisible(true);
  };

  return (
    <>
      <div className="grid m-0">
        <div className="col-12 md:col-4">
          <label htmlFor="Template">Template</label>
          <PrimeReactModule.Dropdown
            value={selectedTemplate}
            onChange={(e) => {
              settempId(e?.value?.templateId);
              templateChange(e);
              setSelectedTemplate(e?.value);
            }}
            className="w-full"
            filter
            name="templateId"
            options={getTemp}
            optionLabel="templateName"
            placeholder="Select a Template"
          />
        </div>
        <div className="col-8 text-right mt-4">
          <PrimeReactModule.Button
            label="Add"
            disabled={selectedTemplate ? false : true}
            onClick={() => setVisible(true)}
          />
        </div>
        <div className="col-12">
          <PrimeReactModule.DataTable
           scrollable
            scrollHeight="calc(100vh - 425px)"
            size="small"
            showGridlines
            paginator
            rows={25}
            responsiveLayout="scroll"
            rowsPerPageOptions={[5, 10, 25, 50]}
            value={getForm}
            editMode="row"
            dataKey="sectionId"
            tableStyle={{minWidth:"50rem"}}
            globalFilterFields={["fieldName", "prompt"]}
            emptyMessage="No Field's Found."
          >
            <PrimeReactModule.Column
              sortable
              field="sNo"
              header="Section No"
              style={{ width: "20%" }}
            ></PrimeReactModule.Column>
            <PrimeReactModule.Column
              sortable
              field="sectionName"
              header="Section Name"
              style={{ width: "20%" }}
            ></PrimeReactModule.Column>
            <PrimeReactModule.Column
              header="Action"
              body={updateTempBody}
              align={"center"}
              className="text-center"
              style={{ width: "20%" }}
            ></PrimeReactModule.Column>
          </PrimeReactModule.DataTable>
        </div>
      </div>
      <Dialog
        maximizable={false}
        headername={"Add"}
        visible={visible}
        footer={footerContent}
        setVisible={() => setVisible(false)}
      >
        <div className="formgrid grid">
          <div className="field col-12 md:col-6">
            <label htmlFor="name">Section No <span className="text-red-700"> *</span></label>
            <PrimeReactModule.InputNumber
              id="name"
              name="sectionNum"
              value={values.sectionNum}
              onBlur={handleBlur}
              onChange={(e) =>
                handleChange({ target: { name: "sectionNum", value: e.value } })
              }
              className={`mt-1 w-full ${classNames({
                "p-invalid": touched.sectionNum && errors.sectionNum,
              })}`}
            />
            {errors.sectionNum && touched.sectionNum && (
              <small className="p-error text-xs">{errors.sectionNum}</small>
            )}
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="name">Section Name <span className="text-red-700"> *</span></label>
            <PrimeReactModule.InputText
              id="name"
              name="SectionName"
              autoComplete="off"
              value={values.SectionName}
              onBlur={handleBlur}
              onChange={handleChange}
              className={`mt-1 w-full ${classNames({
                "p-invalid": touched.SectionName && errors.SectionName,
              })}`}
            />
            {errors.SectionName && touched.SectionName && (
              <small className="p-error text-xs">{errors.SectionName}</small>
            )}
          </div>
        </div>
      </Dialog>
      <Dialog
        maximizable={false}
        headername={"Update"}
        visible={updateVisible}
        footer={updateFooterContent}
        setVisible={() => setUpdateVisible(false)}
      >
        <div className="formgrid grid">
          <div className="field col-12 md:col-6">
            <label htmlFor="name">
              Section No <span className="text-red-700">*</span>
            </label>
            <PrimeReactModule.InputNumber
              id="name"
              name="name"
              value={updateSecNum}
              onChange={(e) => setUpdateSecNum(e.value)}
              className="w-full"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="name">
              Section Name <span className="text-red-700">*</span>
            </label>
            <PrimeReactModule.InputText
              id="name"
              name="name"
              autoComplete="off"
              value={updateSecName}
              onChange={(e) => setUpdateSecName(e.target.value)}
              className="w-full"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Form;
