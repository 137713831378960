import * as Yup from "yup";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { useFormik } from "formik";
import "./../../../styles/login.scss";
import React, { useEffect } from "react";
import { classNames } from "primereact/utils";
import { http } from "./../../../services/http.service";
import { Link, useNavigate } from "react-router-dom";
import PrimeReactModule from "./../../../utils/context/PrimeReact";
import { LoginModal } from "./../../../utils/modals/Models.interface";
import { RouterProps } from "./../../../utils/modals/Models.interface";
import {
  setAuthUser,
  isAuthUser,
  removeAll,
} from "./../../../services/getLocalData";

const Login: React.FC<RouterProps> = ({ show, progressRef, prog }) => {
  // const { transcript, resetTranscript } = useSpeechRecognition();

  // useEffect(() => {
  //   SpeechRecognition.startListening({ continuous: true });
  //   console.log("Listening Starts");
  // }, []);

  const navigate = useNavigate();
  
  useEffect(() => {
    document.title = "Login - CogniCraft";
    if (isAuthUser()) {
      navigate("/main/tree");
    } else {
      removeAll();
    }
    progressRef.current.complete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const keyEnter = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleSubmit();
      }
    };
    document.addEventListener("keydown", keyEnter);
    return () => {
      document.removeEventListener("keydown", keyEnter);
    };
  });

  //onSubmit
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
  }: any = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Invalid email format"
        ),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    }),
    onSubmit: async (data: any) => {
      
      let body: LoginModal = {
        email: data.email.toLowerCase(),
        password: data.password,
      };
      prog(50);
      await http.post("login", body).then((res: any) => {
        if (res) {
          if (res.code === 200) {
            setAuthUser(res.data);
            // navigate("/dashboard/document")
            navigate("/cognicraft");
          }
          prog(100);
        } else {
          prog(100);
        }
      });
    },
  });

  const footer = (
    <div className="flex flex-wrap justify-content-between align-items-center gap-2">
      <Link to="/register">
        <span className="text-800"> Not a user?</span> Register
      </Link>
      <PrimeReactModule.Button
        size="small"
        type="submit"
        disabled={!isValid}
        severity="info"
        onClick={() => handleSubmit()}
        label="Login"
      >
        <PrimeReactModule.Ripple />
      </PrimeReactModule.Button>
    </div>
  );
  //templates end

  return (
    <>
      <div className="w-full h-screen login">
        <div className="grid grid-nogutter relative h-full">
          {/* <div className="xl:col-5 lg:col-6 none md:flex bg-image"></div> */}
          <div className="xl:col-12 lg:col-12 col-12 flex justify-content-center align-items-center">
            <PrimeReactModule.Card
              title="Login"
              subTitle="CogniCraft"
              footer={footer}
              // header={header}
              className="md:w-30rem sm:max-w-full"
            >
              <div className="formgrid grid">
                <div className="field col-12 m-0">
                  <div className="flex flex-column ">
                    <label htmlFor="email">
                      Email: <span className="text-red-700">*</span>
                    </label>
                    <PrimeReactModule.InputText
                      id="email"
                      name="email"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className={`mt-1 ${classNames({
                        "p-invalid": touched.email && errors.email,
                      })}`}
                    />
                    {errors.email && touched.email && (
                      <small className="p-error text-xs">{errors.email}</small>
                    )}
                  </div>
                </div>
                <div className="field col-12 m-0 mt-1">
                  <div className="flex flex-column">
                    <label htmlFor="password">
                      Password: <span className="text-red-700">*</span>
                    </label>
                    <PrimeReactModule.Password
                      id="password"
                      name="password"
                      feedback={false}
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      className={`w-full mt-1 ${classNames({
                        "p-invalid": errors.password && touched.password,
                      })}`}
                      toggleMask
                    />
                    {errors.password && touched.password && (
                      <small className="p-error text-xs">
                        {errors.password}
                      </small>
                    )}
                  </div>
                </div>
              </div>
            </PrimeReactModule.Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
