import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import PrimeReactModule from "../../../utils/context/PrimeReact";
import { http } from "../../../services/http.service";
import { getAuthUser } from "../../../services/getLocalData";
import React from "react";
import { useNavigate } from "react-router-dom";
import AiSpinner from "../../../utils/context/AiSpinner";
import { useFormik } from "formik";
import * as Yup from "yup";
import { classNames } from "primereact/utils";
import { confirmDialog } from "primereact/confirmdialog";

const Abstarction = forwardRef(
  ({ DocumentId, onShowSpinner,isAbstractAllows }: any, ref: any) => {
    const navigate = useNavigate();
    const [getTemp, setgetTemp] = useState<any>([]);
    const [tempId, setTeamTd] = useState<any>();
    var ChatGpt: any = [
      { name: "ChatGpt 3.5", id: 1, value: true },
      { name: "ChatGpt 4", id: 2, value: true },
    ];
    const [gpt, setGpt] = useState<any>(ChatGpt[0].id);
    useEffect(() => {
      getTemplate();
    }, []);

    const getTemplate = () => {
      http.get("template/data").then((res: any) => {
        if (res) {
          setgetTemp(res?.data);
        }
      });
    };

    const gettemplatedata = (data: any) => {
      http
        .get("get/templatedata/" + data.template + "/" + DocumentId)
        .then((res: any) => {
          if (res) {
            startAbstract(data);
          }
        });
    };

    const startAbstract = (data: any) => {
      onShowSpinner(true);
      var body = {
        version: data.chatGpt,
        templateId: data.template,
        documentId: DocumentId,
      };
      http
        .post("start/document/abstract", body)
        .then((res: any) => {
          if (res) {
            navigate(
              "/document/" +
                getAuthUser()?.userid +
                "/" +
                DocumentId +
                "/" +
                values.template
            );
          }
          onShowSpinner(false);
        })
        .catch((error) => {
          onShowSpinner(false);
        });
    };

    const {
      handleSubmit,
      handleChange,
      handleBlur,
      values,
      errors,
      touched,
      isValid,
    }: any = useFormik({
      initialValues: {
        template: "",
        chatGpt: ChatGpt[0].id,
      },
      validationSchema: Yup.object({
        template: Yup.string().required("Template is required"),
        chatGpt: Yup.string().required("ChatGpt is required"),
      }),
      onSubmit: async (data: any) => {
          gettemplatedata(data);
      },
    });

    return (
      <div className="grid m-0 mt-2">
        {isAbstractAllows?.data?.isAbstract ? (
          <>
            <div className="field col-12 md:col-4 mt-2">
              <label htmlFor="name" className="pl-2 text-base">
                Template Type :
              </label>
              &nbsp;&nbsp;
              <span className="text-base">
                {isAbstractAllows?.data?.templateName}
              </span>
            </div>
            <div className="field col-12 md:col-4 mt-2">
              <label htmlFor="name" className="pl-2 text-base">
                Models :
              </label>
              &nbsp;&nbsp;
              <span className="text-base">
                {isAbstractAllows?.version == 1 ? "ChatGpt 3.5" : "ChatGpt 4"}
              </span>
            </div>
            <div className="col-12 text-right">
              <PrimeReactModule.Button
                type="submit"
                disabled={!isValid}
                severity="info"
                onClick={() =>
                  navigate(
                    "/document/" +
                      getAuthUser()?.userid +
                      "/" +
                      DocumentId +
                      "/" +
                      isAbstractAllows?.data?.templateId
                  )
                }
                label="View Abstarction"
              ></PrimeReactModule.Button>
            </div>
          </>
        ) : (
          <>
            {" "}
            <div className="field col-12 md:col-4">
              <label htmlFor="name" className="pl-2">
                Template Type
                <span className="text-red-700">*</span>
              </label>
              <PrimeReactModule.Dropdown
                filter
                value={values.template}
                name="template"
                options={getTemp}
                optionLabel="templateName"
                optionValue="templateId"
                disabled={isAbstractAllows?.isAbstract}
                filterBy="templateName"
                placeholder="Select a Template"
                style={{ width: "250px" }}
                onBlur={handleBlur}
                onChange={(e) => (handleChange(e), setTeamTd(e.target.value))}
                className={`mt-2 w-full ${classNames({
                  "p-invalid": touched.template && errors.template,
                })}`}
              />
              {errors.template && touched.template && (
                <small className="p-error text-xs">{errors.template}</small>
              )}
            </div>
            <div className="field col-12 md:col-4">
              <label htmlFor="name" className="pl-2">
                Models <span className="text-red-700">*</span>
              </label>
              <PrimeReactModule.Dropdown
                onBlur={handleBlur}
                onChange={(e) => (handleChange(e), setGpt(e.target.value))}
                filter
                name="chatGpt"
                options={ChatGpt}
                value={values.chatGpt}
                disabled={isAbstractAllows?.isAbstract}
                optionLabel="name"
                optionValue="id"
                filterBy="name"
                placeholder="Select a Model"
                style={{ width: "250px" }}
                className={`mt-2 w-full ${classNames({
                  "p-invalid": touched.chatGpt && errors.chatGpt,
                })}`}
              />
              {errors.chatGpt && touched.chatGpt && (
                <small className="p-error text-xs">{errors.chatGpt}</small>
              )}
            </div>
            <div className="col-12 text-right">
              <PrimeReactModule.Button
                type="submit"
                disabled={!isValid}
                severity="info"
                onClick={() => handleSubmit()}
                label="Start Abstarction"
              ></PrimeReactModule.Button>
            </div>
          </>
        )}
        <PrimeReactModule.ConfirmDialog />
      </div>
    );
  }
);

export default Abstarction;
