import './styles/App.scss';
import PrimeReact from "primereact/api";
import { useMountEffect } from "primereact/hooks";
import LoadingBar from 'react-top-loading-bar'
import React, { useEffect, useRef, useState } from 'react'
import Routers from './routers';
import { setNavigateToLogin, setToastRef } from "./../src/services/http.service";
import PrimeReactModule from './utils/context/PrimeReact';
import { useNavigate } from 'react-router-dom';

const App: React.FC<any> = () => {
  const toast: any = useRef(null);
  const navigate = useNavigate();

  useMountEffect(() => {
    PrimeReact.ripple = true;
  });

  const [progress, setProgress] = useState(0);
  const ref = useRef(null);

  const show: any = (summary: any, msg: any, type: string) => {
    switch (type) {
      case 'success':
        toast.current.show({
          severity: "success",
          summary: summary,
          detail: msg,
        });
        break;
      case 'error':
        toast.current.show({
          severity: "error",
          summary: summary,
          detail: msg,
        });
        break;
      case 'info':
        toast.current.show({
          severity: "info",
          summary: summary,
          detail: msg,
        });
        break;
      case 'warn':
        toast.current.show({
          severity: "warn",
          summary: summary,
          detail: msg,
        });
        break;
    }
  };

  // handler for 401 and 500 start
  useEffect(() => {
    setToastRef(toast);
    const navigateToLogin = () => {
      navigate('/login');
    };
    setNavigateToLogin(navigateToLogin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // handler for 401 and 500 end

  return (
    <>
      <PrimeReactModule.Toast ref={toast} appendTo={document.getElementById("root")} />
      <LoadingBar
        color='#f11946'
        progress={progress}
        shadow={true}
        onLoaderFinished={() => setProgress(0)}
      />
      <LoadingBar
        ref={ref}
        color='var(--primary-color)'
      />
      <Routers show={show} progressRef={ref} prog={setProgress} />
    </>
  );
}

export default App;
