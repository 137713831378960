import { useEffect } from "react";
import { forwardRef, useImperativeHandle, useState } from "react";
import PrimeReactModule from "./../../../../../utils/context/PrimeReact";
import DataField from "./DataField";

function Form(props: any, ref: any) {
  const [activeIndex, setActiveIndex] = useState<any>();
  const { dataSet, setDataSet, prog, show, onTriggerEvent, permission } = props;

  useEffect(() => {
    setActiveIndex(dataSet?.map((_: any, index: any) => index));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    toggleCollapse,
    expantIndividual,
  }));

  const toggleCollapse = () => {
    if (activeIndex?.length === dataSet?.length) {
      setActiveIndex([]);
    } else {
      setActiveIndex(dataSet?.map((_: any, index: any) => index));
    }
  };

  const expantIndividual = (index: any) => {
    let _activeIndex = activeIndex ? [...activeIndex] : [];
    const indexs = _activeIndex.indexOf(index);

    if (indexs === -1)
      _activeIndex.push(index);

    setActiveIndex(_activeIndex);
  }

  return (
    <>
      <PrimeReactModule.Accordion
        multiple
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        {dataSet.map((data: any, index: any) => (
          <PrimeReactModule.AccordionTab
            key={index}
            header={<span className="font-bold">{index + 1 + " . " + data.sectionName + ":"}</span>}
          >
            <DataField onTriggerEvent={onTriggerEvent} show={show} prog={prog} data={data} setDataSet={setDataSet} baseindex={index} permission={permission}></DataField>
          </PrimeReactModule.AccordionTab>
        ))}

      </PrimeReactModule.Accordion>
    </>
  )
}

export default forwardRef(Form);