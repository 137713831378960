import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { removeAll } from '../../services/getLocalData';
import logo from './../../assets/Images/Logo/dark-logo.png';
import error from './../../assets/Images/notfound/error.png';
import PrimeReactModule from '../../utils/context/PrimeReact';
import { progressRefRouterProps } from "./../../utils/modals/Models.interface";

const PageNotFound: React.FC<progressRefRouterProps> = ({ progressRef }) => {
    let style = {
        backgroundColor: '#353535'
    }

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "CogniCraft - 404";
        progressRef.current.complete();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='w-full h-screen	flex flex-column justify-content-center	align-items-center'>
            <div className='fixed top-0 h-4rem w-full flex align-items-center pl-4' style={style}>
                <img src={logo} alt="" className='w-8rem h-1rem' />
            </div>
            <div className='flex flex-column justify-content-center	align-items-center'>
                <img src={error} alt="404" width="300" />
                <h5>PAGE NOT FOUND</h5>
                <p className='font-bold'>An error has occurred while processing your request.</p>
                <span className="p-buttonset">
                    <PrimeReactModule.Button severity="warning" text label="Login" onClick={() => { removeAll(); navigate('/login') }} icon="pi pi-sign-in" />
                    <PrimeReactModule.Button severity="secondary" text label="Back" onClick={() => navigate(-1)} icon="pi pi-arrow-left" />
                </span>
            </div>
            <footer className='fixed bottom-0 h-2rem w-full flex align-items-center pl-4' style={style}>
                <ul className='list-none flex justify-content-between align-items-center w-full'>
                    <li>
                        <p className='text-white text-xs'>Copyright © {new Date().getFullYear()} VisualApp Foundry. All Rights Reserved.</p></li>
                    <li>
                        <ul className='list-none flex justify-content-between align-items-center'>
                            <li><i className="pi pi-facebook"></i></li>
                            <li></li>
                            <li></li>
                            <li></li>
                        </ul>
                    </li>
                </ul>
            </footer>
        </div>
    );
}

export default PageNotFound;