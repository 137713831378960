// import { useEffect, useState } from "react";
// import { http } from "../../../../services/http.service";
// import PrimeReactModule from "../../../../utils/context/PrimeReact";
// import DisableButton from "../../../../utils/hook/disableButton";

// const Prompt = ({ show }: any) => {
//   const [template, setTemplate] = useState<any>();
//   const [section, setSection] = useState<any>();
//   const [promptData, setPromptData] = useState<any>();
//   const [selectedTemplate, setSelectedTemplate] = useState<any>();
//   const [selectedSection, setSelectedSection] = useState<any>();
//   const { buttonEnable, buttonDisable } = DisableButton();

//   useEffect(() => {
//     getTemplateDetails();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   const getTemplateDetails = async () => {
//     await http.get("get/template").then((res: any) => {
//       if (res) {
//         setTemplate(res?.data);
//       }
//     });
//   };

//   const templateChange = async (e: any) => {
//     await http.get("get/section/" + e?.value.templateId).then((res: any) => {
//       if (res) {
//         setSection(res);
//         setPromptData([]);
//       }
//     });
//   };

//   const sectionChange = async (e: any) => {
//     const SectionId: any = e?.value
//       ? e?.value.sectionId
//       : selectedSection.sectionId;
//     await http.get("get/field/prompts/" + SectionId).then((res: any) => {
//       if (res) {
//         setPromptData(res);
//       }
//     });
//   };

//   const textEditor = (options: any) => {
//     return (
//       <PrimeReactModule.InputText
//         type="text"
//         value={options.value}
//         className="w-full"
//         onChange={(e) => options.editorCallback(e.target.value)}
//       />
//     );
//   };

//   const onRowEditComplete = async (e: any) => {
//     buttonEnable();
//     var body: any = {
//       prompt: e.newData.prompt.trim(),
//       status: e.newData.prompt ? true : false,
//       keyword: e.newData.dKeyword.trim()
//     };
//     await http
//       .post(
//         "update/template/section/form/field/prompts/" + e.newData.promptId,
//         body
//       )
//       .then((res: any) => {
//         if (res.code === 200) {
//           show("Success", res.data, "success");
//           sectionChange("");
//         }
//       });
//   };

//   const onRowEditInit = () => {
//     buttonDisable();
//   };

//   const onRowEditCancel = () => {
//     buttonEnable();
//   };

//   const DeleteIcon = (options: any) => {
//     return (
//       <PrimeReactModule.Button
//         icon="pi pi-trash"
//         tooltip="Delete"
//         tooltipOptions={{ position: "top" }}
//         className="text-red-600 bg-white border-none"
//         style={{ fontSize: "1rem", cursor: "pointer" }}
//         onClick={() => deletePrompt(options)}
//       ></PrimeReactModule.Button>
//     );
//   };

//   const deletePrompt = (options: any) => {
//     http.delete("prompt/delete/" + options.promptId).then((res: any) => {
//       if (res.code === 200) {
//         show("Success", res.data, "success");
//         sectionChange("");
//       }
//     });
//   };

//   return (
//     <>
//       <div className="grid m-0">
//         <div className="col-12 md:col-4">
//           <label htmlFor="Template">Template</label>
//           <PrimeReactModule.Dropdown
//             value={selectedTemplate}
//             onChange={(e) => {
//               templateChange(e);
//               setSelectedTemplate(e?.value);
//             }}
//             className="w-full"
//             filter
//             name="promptId"
//             options={template}
//             optionLabel="templateName"
//             placeholder="Select a Template"
//           />
//         </div>
//         <div className="col-12 md:col-4">
//           <label htmlFor="Section">Section</label>
//           <PrimeReactModule.Dropdown
//             value={selectedSection}
//             onChange={(e) => {
//               sectionChange(e);
//               setSelectedSection(e?.value);
//             }}
//             options={section}
//             optionLabel="sectionName"
//             filter
//             placeholder="Select a Section"
//             className="w-full"
//           />
//         </div>
//         <div className="col-12 md:col-12">
//           <PrimeReactModule.DataTable
//           scrollable
//           scrollHeight="calc(100vh - 305px)"
//             size="small"
//             showGridlines
//             paginator
//             rows={25}
//             responsiveLayout="scroll"
//             rowsPerPageOptions={[5, 10, 25, 50]}
//             value={promptData}
//             editMode="row"
//             dataKey="fieldId"
//             onRowEditComplete={onRowEditComplete}
//             onRowEditInit={onRowEditInit}
//             onRowEditCancel={onRowEditCancel}
//             tableStyle={{ minWidth: "50rem" }}
//             globalFilterFields={["fieldName", "prompt"]}
//             emptyMessage="No Field's Found."
//           >
//             <PrimeReactModule.Column
//               sortable
//               field="fieldName"
//               header="Clause Name"
//               style={{ width: "20%" }}
//             ></PrimeReactModule.Column>
//             <PrimeReactModule.Column
//               sortable
//               field="prompt"
//               header="Prompt"
//               className="white-space-normal"
//               editor={(options) => textEditor(options)}
//               style={{ width: "40%" }}
//             ></PrimeReactModule.Column>
//             <PrimeReactModule.Column
//               sortable
//               field="dKeyword"
//               header="Keyword"
//               className="white-space-normal"
//               editor={(options) => textEditor(options)}
//               style={{ width: "30%" }}
//             ></PrimeReactModule.Column>
//             <PrimeReactModule.Column
//               header="Delete"
//               className="text-center"
//               body={DeleteIcon}
//               align={"center"}
//               style={{ width: "5%" }}
//             ></PrimeReactModule.Column>
//             <PrimeReactModule.Column
//               header="Action"
//               rowEditor
//               align={"center"}
//               className="text-center"
//               style={{ width: "5%" }}
//             ></PrimeReactModule.Column>
//           </PrimeReactModule.DataTable>
//         </div>
//       </div>
//     </>
//   );
// };

// export default Prompt;

import { useEffect, useState } from "react";
import { http } from "../../../../services/http.service";
import PrimeReactModule from "../../../../utils/context/PrimeReact";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import Dialog from "../../../../components/Dialog";

const Prompt = ({ show }: any) => {
  const [template, setTemplate] = useState<any>();
  const [section, setSection] = useState<any>();
  const [promptData, setPromptData] = useState<any>();
  const [selectedTemplate, setSelectedTemplate] = useState<any>();
  const [selectedSection, setSelectedSection] = useState<any>();
  const [filters, setFilters]: any = useState(null);
  const [updateVisible, setUpdateVisible] = useState<any>(false);
  const [updateFormData, setUpdateFormData] = useState<any>({
    prompt: null,
    keyword: null,
    promptId: null,
    field: null,
  });

  useEffect(() => {
    getTemplateDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTemplateDetails = async () => {
    await http.get("lookup/get/template").then((res: any) => {
      if (res) {
        setTemplate(res?.data);
      }
    });
  };

  const templateChange = async (e: any) => {
    await http.get("get/section/" + e?.value.templateId).then((res: any) => {
      if (res) {
        setSection(res);
        setPromptData([]);
      }
    });
  };

  const sectionChange = async (e: any) => {
    const SectionId: any = e?.value
      ? e?.value.sectionId
      : selectedSection.sectionId;
    await http.get("get/field/prompts/" + SectionId).then((res: any) => {
      if (res) {
        setPromptData(res);
      }
    });
  };

  const textEditor = (options: any) => {
    return (
      <PrimeReactModule.InputText
        type="text"
        value={options.value}
        className="w-full"
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const deletePrompt = (options: any) => {
    http.delete("prompt/delete/" + options.promptId).then((res: any) => {
      if (res.code === 200) {
        show("Success", res.data, "success");
        sectionChange("");
      }
    });
  };

  const updateTempBody = (options: any) => {
    return (
      <>
        <div>
          <PrimeReactModule.Button
            icon="pi pi-pencil"
            tooltip="Edit"
            tooltipOptions={{ position: "top" }}
            className="text-green-600 bg-white border-none"
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={() => rowEdit(options)}
          ></PrimeReactModule.Button>
          <PrimeReactModule.Button
            icon="pi pi-trash"
            tooltip="Delete"
            tooltipOptions={{ position: "top" }}
            className="text-red-600 bg-white border-none"
            style={{ fontSize: "1rem", cursor: "pointer" }}
            onClick={() => deletePrompt(options)}
          ></PrimeReactModule.Button>
        </div>
      </>
    );
  };

  const rowEdit = (options: any) => {
    
    setUpdateFormData({
      ...updateFormData,
      prompt: options.prompt,
      keyword: options.dKeyword,
      promptId: options.promptId,
      field: options.fieldName,
    });
    setUpdateVisible(true);
  };

  const updateFooterContent = (
    <div>
      <PrimeReactModule.Button
        label="Cancel"
        onClick={() => {
          setUpdateVisible(false);
          setUpdateFormData({});
        }}
        className="p-button-secondary"
      />
      <PrimeReactModule.Button
        label="Update"
        onClick={() => UpdateTemplate()}
        autoFocus
      />
    </div>
  );

  const UpdateTemplate = async () => {
    var body: any = {
      prompt: updateFormData.prompt,
      status: updateFormData.prompt ? true : false,
      keyword: updateFormData.keyword.replace(/\s+/g, " "),
    };
    await http
      .post(
        "update/template/section/form/field/prompts/" + updateFormData.promptId,
        body
      )
      .then((res: any) => {
        if (res.code === 200) {
          show("Success", res.data, "success");
          sectionChange("");
        }
        setUpdateVisible(false);
      });
  };

  return (
    <>
      <div className="grid m-0">
        <div className="col-12 md:col-4">
          <label htmlFor="Template">Template</label>
          <PrimeReactModule.Dropdown
            value={selectedTemplate}
            onChange={(e) => {
              templateChange(e);
              setSelectedTemplate(e?.value);
            }}
            className="w-full"
            filter
            name="promptId"
            options={template}
            optionLabel="templateName"
            placeholder="Select a Template"
          />
        </div>
        <div className="col-12 md:col-4">
          <label htmlFor="Section">Section</label>
          <PrimeReactModule.Dropdown
            value={selectedSection}
            onChange={(e) => {
              sectionChange(e);
              setSelectedSection(e?.value);
            }}
            options={section}
            optionLabel="sectionName"
            filter
            placeholder="Select a Section"
            className="w-full"
          />
        </div>
        <div className="col-12 md:col-12">
          <PrimeReactModule.DataTable
            size="small"
            scrollable 
            scrollHeight="calc(100vh - 425px)"
            showGridlines
            paginator
            rows={25}
            rowsPerPageOptions={[5, 10, 25, 50]}
            value={promptData}
            editMode="row"
            dataKey="fieldId"
            filters={filters}
            globalFilterFields={["fieldName", "prompt"]}
            tableStyle={{ minWidth: "50rem" }}
            emptyMessage="No Field's Found."
          >
            <PrimeReactModule.Column
              sortable
              field="fieldName"
              header="Field"
              style={{ width: "20%" }}
              filterField="fieldName"
              filter
              filterPlaceholder="Search by Field"
            ></PrimeReactModule.Column>
            <PrimeReactModule.Column
              sortable
              field="prompt"
              header="Prompt"
              style={{ width: "60%" }}
              className="white-space-normal"
              editor={(options) => textEditor(options)}
              filterField="prompt"
              filter
              filterPlaceholder="Search by prompt"
            ></PrimeReactModule.Column>
            <PrimeReactModule.Column
              sortable
              field="dKeyword"
              header="Keyword"
              className="white-space-normal"
              editor={(options) => textEditor(options)}
              style={{ width: "30%" }}
            ></PrimeReactModule.Column>
            <PrimeReactModule.Column
              header="Action"
              body={updateTempBody}
              alignHeader={"center"}
              className="text-center"
              style={{ width: "10%" }}
            ></PrimeReactModule.Column>
          </PrimeReactModule.DataTable>
        </div>
      </div>
      <Dialog
        maximizable={false}
        headername={"Update Prompt"}
        visible={updateVisible}
        footer={updateFooterContent}
        setVisible={() => setUpdateVisible(false)}
      >
        <div className="formgrid grid">
          <div className="field col-12 md:col-12">
            <label htmlFor="name">
              Prompt <span className="text-red-700">*</span>
            </label>
            <PrimeReactModule.InputTextarea
              id="name"
              name="name"
              rows={Math.max(3, Math.ceil(updateFormData.prompt?.length / 90))}
              value={updateFormData.prompt}
              onChange={(e) =>
                setUpdateFormData({
                  ...updateFormData,
                  prompt: e.target.value,
                })
              }
              className="w-full"
            />
          </div>
          <div className="field col-12 md:col-12">
            <label htmlFor="name">
              Keyword <span className="text-red-700">*</span>
            </label>
            <PrimeReactModule.InputText
              id="name"
              name="name"
              value={updateFormData.keyword}
              onChange={(e) =>
                setUpdateFormData({
                  ...updateFormData,
                  keyword: e.target.value,
                })
              }
              className="w-full"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Prompt;
