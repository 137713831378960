import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Ripple } from 'primereact/ripple';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Menu } from 'primereact/menu';
import { PanelMenu } from 'primereact/panelmenu';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import { BreadCrumb } from 'primereact/breadcrumb';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Divider } from 'primereact/divider';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Dropdown } from 'primereact/dropdown';
import { Splitter, SplitterPanel } from "primereact/splitter";
import { SplitButton } from 'primereact/splitbutton';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { ToggleButton } from 'primereact/togglebutton';
import { TabView, TabPanel } from 'primereact/tabview';
import { Tree } from 'primereact/tree';
import { InputSwitch } from 'primereact/inputswitch';
import { Calendar } from 'primereact/calendar';
import { TreeTable } from 'primereact/treetable';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import { ScrollTop } from 'primereact/scrolltop'; import { TieredMenu } from 'primereact/tieredmenu';
import { Messages } from 'primereact/messages';
import { Checkbox } from 'primereact/checkbox';
import { SpeedDial } from 'primereact/speeddial';
import { Skeleton } from 'primereact/skeleton';
import { ConfirmPopup } from 'primereact/confirmpopup';

const PrimeReactModule = {
    OverlayPanel,
    InputTextarea,
    Column,
    TreeTable,
    Accordion,
    SpeedDial,
    TabView,
    TabPanel,
    Messages,
    AccordionTab,
    Splitter,
    SplitButton,
    SplitterPanel,
    Dropdown,
    AutoComplete,
    Card,
    ToggleButton,
    Button,
    Ripple,
    Toast,
    InputText,
    Password,
    Menu,
    PanelMenu,
    Dialog,
    FileUpload,
    DataViewLayoutOptions,
    DataView,
    ProgressBar,
    Tooltip,
    Tag,
    BreadCrumb,
    DataTable,
    Divider,
    InputNumber,
    confirmDialog,
    ConfirmPopup,
    ConfirmDialog,
    Tree,
    InputSwitch,
    Calendar,
    MultiSelect,
    RadioButton,
    ScrollTop,
    TieredMenu,
    Checkbox,
    Skeleton
}

export default PrimeReactModule;