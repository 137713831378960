import ReactDOM from 'react-dom/client';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import "./assets/styles/layout/layout.scss";
import "./assets/styles/demo/Demos.scss";
import './styles/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import * as serviceWorker from './serviceWorker';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <DndProvider backend={HTML5Backend}>
    <Router>
      <App />
    </Router>
  </DndProvider>
);

reportWebVitals();
serviceWorker.unregister();