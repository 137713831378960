import PageNotFound from './PageNotFound';
import { Routes, Route, Navigate } from 'react-router-dom';
import { RouterProps } from './../utils/modals/Models.interface';
import { DocumentAbstraction, Login, Register } from './../Module';
import DocAssist from '../Module/Docassist';
import React from 'react';
import AdminPanel from '../Module/AdminPanel';

const Routers: React.FC<RouterProps> = ({ show, progressRef, prog }) => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login show={show} progressRef={progressRef} prog={(no: any) => prog(no)} />} />
        <Route path="/register" element={<Register show={show} progressRef={progressRef} prog={(no: any) => prog(no)} />} />
        <Route path="/pagenotfound" element={<PageNotFound progressRef={progressRef} />} />
        <Route path="/cognicraft" element={<DocAssist show={show} progressRef={progressRef} prog={(no: any) => prog(no)}/>} />
        <Route path="/adminpanel" element={<AdminPanel/>} />
        <Route path='document/:usertypeId/:DocumentId/:templateId' element={<DocumentAbstraction show={show} progressRef={progressRef} prog={(no: any) => prog(no)} />} />
        <Route path="*" element={<Navigate to="/pagenotfound" replace />} />
      </Routes>
    </div>
  );
};

export default Routers;