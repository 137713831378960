import React from "react";
import PrimeReactModule from "./../../utils/context/PrimeReact";
interface DialogProps {
  headername: any;
  visible: boolean;
  footer: any;
  setVisible: (value: boolean) => void;
  children: React.ReactNode;
  maximizable: boolean;
}

const Dialog: React.FC<DialogProps> = ({
  headername,
  visible,
  footer,
  setVisible,
  children,
  maximizable,
}) => {
  return (
    <>
      <PrimeReactModule.Dialog
        header={headername}
        visible={visible}
        style={{ width: "50vw" }}
        onHide={() => setVisible(false)}
        dismissableMask={false}
        draggable={false}
        footer={footer}
        closable={false}
        modal={true}
        blockScroll={true}
        appendTo={document.getElementById("root")}
        maximizable={maximizable}
        position="top"
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        {children}
      </PrimeReactModule.Dialog>
    </>
  );
};

export default Dialog;
