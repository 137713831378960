import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PrimeReactModule from "../../../utils/context/PrimeReact";
import { http } from "../../../services/http.service";
import { getAuthUser } from "../../../services/getLocalData";
import React from "react";

const Chat = forwardRef(({ DocumentId, show }: any, ref: any) => {
  const [data, setdata] = useState<any>();
  const [accordianData, setAccordianData] = useState<any>();
  const [getText, setGetText] = useState<any>();
  const [documentId] = useState<any>(DocumentId);
  const [showSkeleton, setShowSkeleton] = useState<any>(true);
  const lastElementRef = useRef<any>(null);
  useEffect(() => {
    debugger;
    getChatData();
  }, []);

  useEffect(() => {
    const keyEnter = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();
        askQuestion();
      }
    };
    document.addEventListener("keydown", keyEnter);
    return () => {
      document.removeEventListener("keydown", keyEnter);
    };
  });

  useImperativeHandle(ref, () => ({
    toggleCollapse,
  }));

  const toggleCollapse = (val: any) => {
    setdata([]);
    setShowSkeleton(true);
    http.get("get/chat/" + val.target.value).then((res: any) => {
      setdata(res.data);
      setShowSkeleton(false);
    });
  };

  var resultArray: any = [];
  const getChatData = () => {
    debugger;
    setShowSkeleton(true);
    http.get("get/chat/" + documentId).then((res: any) => {
      if (res.code === 200) {
        setdata(res.data);
        let currentQA: any = {};
        res?.data[1]?.answer?.split("\n").forEach((item: any) => {
          if (item.startsWith("Question")) {
            currentQA = {
              question: item.replace("Question: ", ""),
              answer: "",
            };
          } else if (item.startsWith("Answer")) {
            currentQA.answer = item.replace("Answer: ", "");
            resultArray.push({ ...currentQA });
          }
        });
        setAccordianData(resultArray);
        setShowSkeleton(false);
        scrollDown();
      }
    });
  };

  const scrollDown = () => {
    setTimeout(() => {
      const scrollOptions: ScrollIntoViewOptions = {
        behavior: "smooth",
        block: "end",
      };
      if (lastElementRef.current) {
        lastElementRef.current.scrollIntoView(scrollOptions);
      }
    }, 500);
  };

  const askQuestion = async () => {
    const response = await fetch("https://geolocation-db.com/json/");
    const data = await response.json();
    if (getText) {
      setShowSkeleton(true);
      scrollDown();
      var body: any = {
        documentId: documentId,
        userId: getAuthUser()?.userid,
        question: getText,
        ip_address: data.IPv4,
      };
      http.post("send/receive", body).then((res: any) => {
        if (res) {
          getChatData();
          setGetText("");
        }
        setShowSkeleton(false);
      });
    } else {
      show("Required", "Question is Required", "error");
    }
  };

  return (
    <>
      <section className="msger">
        <main className="msger-chat">
          {" "}
          {data?.map((res: any, key: any) => (
            <span key={key} ref={lastElementRef}>
              {res.questions == null ? (
                <></>
              ) : (
                <div className="msg right-msg">
                  <div className="msg-bubble">
                    <div className="msg-info">
                      <div className="msg-info-name">User</div>
                      <div className="msg-info-time">{res.questionDate}</div>
                    </div>
                    <div className="msg-text">{res.questions}</div>
                  </div>
                </div>
              )}
              <div className="msg left-msg">
                <div className="msg-bubble mt-2">
                  <div className="msg-info">
                    <div className="msg-info-name">Bot</div>
                    <div className="msg-info-time">{res.answerDate}</div>
                  </div>
                  <div className="msg-text">
                    {key === 1 && accordianData.length > 1 ? (
                      <>
                        {" "}
                        {accordianData?.map((val: any, keys: any) => (
                          <PrimeReactModule.Accordion
                            // multiple
                            key={keys}
                            activeIndex={[0]}
                            className="mt-2"
                          >
                            <PrimeReactModule.AccordionTab
                              header={val.question}
                            >
                              <p className="m-0">{val.answer}</p>
                            </PrimeReactModule.AccordionTab>
                          </PrimeReactModule.Accordion>
                        ))}
                      </>
                    ) : (
                      <>
                        {" "}
                        {res?.answer
                          ?.split("\n")
                          .map((point: any, index: any) => (
                            <React.Fragment key={index}>
                              {index > 0 && <br />}
                              <span
                              // className={`${
                              //   key === 1 ? "cursor-pointer" : ""
                              // }`}
                              // onClick={() =>
                              //   key === 1 ? getQuestion(point) : ""
                              // }
                              >
                                {" "}
                                {point}
                              </span>
                            </React.Fragment>
                          ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </span>
          ))}
          {showSkeleton ? (
            <span ref={lastElementRef}>
              <div className="msg right-msg">
                <div className="msg-bubble" style={{ background: "white" }}>
                  <div className="msg-info"></div>
                  <PrimeReactModule.Skeleton
                    width="30rem"
                    height="4rem"
                  ></PrimeReactModule.Skeleton>
                </div>
              </div>
              <div className="msg left-msg">
                <div className="msg-bubble" style={{ background: "white" }}>
                  <div className="msg-info"></div>
                  <PrimeReactModule.Skeleton
                    width="30rem"
                    height="4rem"
                  ></PrimeReactModule.Skeleton>
                </div>
              </div>
            </span>
          ) : (
            <></>
          )}
        </main>

        <div className="flex px-3 py-2" style={{ border: "1px solid #DDDDDD" }}>
          <PrimeReactModule.InputText
            type="text"
            className="msger-input"
            placeholder="Enter your message..."
            value={getText}
            onChange={(e) => setGetText(e.target.value)}
          />
          <PrimeReactModule.Button
            type="submit"
            className="ml-2 text-lg"
            onClick={askQuestion}
          >
            Send
          </PrimeReactModule.Button>
        </div>
      </section>
    </>
  );
});

export default Chat;
