import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { removeAll } from './getLocalData';
import { BASE_URL } from './../config';
let navigateToLogin: () => void;
let toastRef: any;

export function setNavigateToLogin(navigate: () => void) {
    navigateToLogin = navigate;
}

export function setToastRef(ref: any) {
    toastRef = ref;
}

const api: AxiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

const apiFile: AxiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
    },
});

const handleNoResponseError = () => {
    if (navigateToLogin) {
        removeAll();
        navigateToLogin();
    }
};

api.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
        if (!error.response) {
            showToastError({ message: error.code }, { error: error.message });
            handleNoResponseError();
            return { data: false };
        }
        if (error.response?.status === 404) {
            if (toastRef) {
                showToastError(error.response.data, error.response.data);
            }
        } else if (error.response?.status === 400) {
            if (toastRef) {
                showToastError(error.response.data, error.response.data);
            }
        }
        else if (error.response?.status === 401) {
            if (toastRef) {
                showToastError(error.response?.data, error.response?.data);
            }
            if (navigateToLogin) {
                removeAll();
                navigateToLogin();
            }
        } else if (error.response?.status === 500) {
            if (toastRef) {
                showToastError({ message: "Error" }, { error: 'Internal Server Error' });
            }
            return { data: false };
            // return Promise.reject(error);
        } else {
            if (toastRef) {
                showToastError({ message: "Exception" }, { error: 'Something went Wrong' });
            }
            return { data: false };
            // return Promise.reject(error);
        }
    }
);

const showToastError = (summary: any, detail: any) => {
    if (toastRef) {
        toastRef.current.show({
            severity: 'error',
            summary: summary.message,
            detail: detail.error
        });
    }
};

apiFile.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
        if (!error.response) {
            showToastError({ message: error.code }, { error: error.message });
            handleNoResponseError();
            return { data: false };
        }
        if (error.response?.status === 404) {
            if (toastRef) {
                showToastError(error.response.data, error.response.data);
            }
        } else if (error.response?.status === 400) {
            if (toastRef) {
                showToastError(error.response.data, error.response.data);
            }
        }
        else if (error.response?.status === 401) {
            if (toastRef) {
                showToastError(error.response?.data, error.response?.data);
            }
            if (navigateToLogin) {
                removeAll();
                navigateToLogin();
            }
        } else if (error.response?.status === 500) {
            if (toastRef) {
                showToastError({ message: "Error" }, { error: 'Internal Server Error' });
            }
        } else {
            if (toastRef) {
                showToastError({ message: "Exception" }, { error: 'Something went Wrong' });
            }
        }
    }
);

const httpClient = {
    async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        try {
            const response: AxiosResponse<T> = await api.get(url, config);
            return response.data;
        } catch (error: any) {
            return error;
        }
    },

    async getbyid<T>(url: string, id: any, config?: AxiosRequestConfig): Promise<T> {
        try {
            const response: AxiosResponse<T> = await api.get(url + '/' + id, config);
            return response.data;
        } catch (error: any) {
            return error;
        }
    },

    async post<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T | null> {
        try {
            const response: AxiosResponse<T> = await api.post<T>(url, data, config);
            return response !== undefined ? response.data : null;
        } catch (error: any) {
            return error;
        }
    },

    async put<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
        try {
            const response: AxiosResponse<T> = await api.put<T>(url, data, config);
            return response.data;
        } catch (error: any) {
            return error;
        }
    },

    async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        try {
            const response: AxiosResponse<T> = await api.delete<T>(url, config);
            return response.data;
        } catch (error: any) {
            return error;
        }
    },
};

const sendFormDataRequest = async (url: any, formData: any) => {
    return await apiFile.post(url, formData);
};

export { httpClient as http, sendFormDataRequest, BASE_URL }