import React from "react";
import PrimeReactModule from "./../../../utils/context/PrimeReact";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Module from "./Module";
import { http } from "./../../../services/http.service";
import { saveAs } from "file-saver";
import csvFile from "./../../../assets/Images/common/export/csv.png";
import excelFile from "./../../../assets/Images/common/export/excel.png";
import { PDfEditor } from "./../../../components";
import Excel from "exceljs";
import Papa from "papaparse";
import { RouterProps } from "../../../utils/modals/Models.interface";
import { BASE_URL } from "../../../services/http.service";
import AiSpinner from "../../../utils/context/AiSpinner";

interface AbstractProps {
  folderId: number;
  templateId: number;
  foldername: string;
  templateName: string;
  isAbstraction: boolean;
  fetchDataInit: () => void;
}

interface RenderProps extends RouterProps, AbstractProps { }

const Abstraction: React.FC<RenderProps> = ({
  show,
  progressRef,
  prog,
  folderId,
  templateId,
  foldername,
  templateName,
  fetchDataInit,
  isAbstraction,
}) => {
  const workbook = new Excel.Workbook();
  const { DocumentId } = useParams();
  const [dataSet, setDataSet]: any = useState<any>([]);
  let navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [isLabel, setIsLabel] = useState(false);
  const useref = useRef<any>(null);
  const msgs = useRef<any>(null);
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [currentfoldername, setFoldername] = useState<string>("");
  const [showDetails, setShowDetails] = useState(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [selectModule, setselectModule] = useState<any>(null);
  const [getField, setgetField] = useState<any>(null);
  const [setField, setSetField] = useState<any>(null);
  const buttonConfirmEl = useRef<any>(null);

  const categories: any = [
    {
      name: "Excel Full Text: Export the extracted fields of the analysis to a Microsoft Office Excel .xlsx format",
      key: "xl",
      Image: excelFile,
    },
    {
      name: "CSV: Export the extracted fields of the analysis to a comma-separated values (.csv) file. Please use 'Excel Full Text' export if you are planning to open the export in MS Excel.",
      key: "csv",
      Image: csvFile,
    },
  ];

  useEffect(() => {
    if (selectedId !== null) {
      // Scroll to the corresponding accordion tab when selectedId changes
      const element = document.getElementById(`accordionTab_${selectedId}`);
      if (element) {
        element.scrollIntoView({ block: "center", behavior: "smooth" });
      }
      setSelectedId(0);
    }
  }, [selectedId]);

  useEffect(() => {
    document.title = "Abstraction - CogniCraft";
    setFoldername(foldername);
    msgs?.current?.clear();
    fetchData();
    progressRef.current.complete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    
    await http
      .get("get/templatedata/" + templateId + "/" + folderId)
      .then((res: any) => {
        if (res) {
          if (res.code === 404) {
            navigate("/main/tree");
            show("Error", res.data, "error");
          } else if (res.code === 400) {
            navigate("/main/tree");
            show("Error", res.data, "error");
          } else {
            if (res.data?.form.length === 0) {
              addMessages();
            }
            setDataSet(res.data?.form);
          }
        }
      });
  };

  const exportToExcel = async (filename: any) => {
    const filenameWithoutExtension = filename.replace(/\.pdf$/, '');
    if (selectedCategory) {
      switch (selectedCategory?.key) {
        case "xl":
          const url = BASE_URL + "export/template/" + templateId + "/" + DocumentId;
          const response = await fetch(url, {
            headers: {
              Authorization: `Bearer `,
            },
          });
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          } else {
            const blob = await response.blob();
            const a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = filenameWithoutExtension + ".xlsx";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(a.href);
          }
          break;
        case "csv":
          const worksheet = workbook.addWorksheet(foldername);
          const combinedArray: any[] = [];
          dataSet?.forEach((obj: any) => {
            combinedArray.push([obj.sectionName]);
            worksheet.addRow([obj.sectionName]);
            obj.field.forEach((field: any) => {
              const fieldLabel = field.label;
              const fieldValue = field.value !== null ? field.value : "";
              combinedArray.push(["", fieldLabel, fieldValue]);
            });
          });
          // Csv Download
          const csvData = convertToCSV(combinedArray);
          const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
          saveAs(blob, `${foldername}.csv`);
          break;
      }
    } else {
      show("Export", "Please Select Csv Or Excel", "error");
    }
  };

  const convertToCSV = (data: Array<any>) => {
    const csv = Papa.unparse(data, { header: true });
    return csv;
  };

  const expandAll = (event: any) => {
    if (useref?.current) {
      useref.current.toggleCollapse(event);
      setIsLabel(!isLabel);
    }
  };

  const childRef: any = useRef<{
    callMethodJumpToPageOnCall: (pageId: any, keyword: any) => void;
  } | null>(null);

  const handleEventjumpToPage = (pageId: number, keyword: string[]) => {
    if (childRef.current) {
      childRef.current.callMethodJumpToPageOnCall(pageId, keyword);
    }
  };

  const addMessages = () => {
    msgs.current?.show([
      {
        sticky: true,
        severity: "info",
        summary: "Not Available",
        detail: "No Module Under Template Currently",
        closable: false,
      },
    ]);
  };

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const selectModuleField = (e: any) => {
    setselectModule(e.target.value);
    setgetField(e.target.value.field);
    if (useref?.current) {
      useref.current.expantIndividual(e.target.value.index);
    }
  };

  const selectField = (e: any) => {
    setSetField(e.target.value);
    progressRef.current.complete();
    setSelectedId(e.target.value);
  };

  // const startAbstraction = async () => {
  //   prog(50);
  //   setShowSpinner(true);
  //   let body = {
  //     documentId: folderId,
  //     templateId: templateId,
  //   };
  //   await http.post("start/template/abstract", body).then((res: any) => {
  //     if (res) {
  //       prog(100);
  //       fetchDataInit();
  //       setShowSpinner(false);
  //       fetchData();
  //     } else {
  //       setShowSpinner(false);
  //       prog(100);
  //     }
  //   });
  // };

  const updateAbstractedValue = async (msg: string, valueId: number) => {
    prog(50);
    let body: any = {
      msg: msg,
    };
    await http
      .put("update/abstract/value/by/" + valueId, body)
      .then((res: any) => {
        if (res.code === 200) {
          fetchData();
          show("Success", res.data, "success");
          prog(100);
        } else {
          prog(100);
        }
      });
  };

  return (
    <div className="grid grid-nogutter col-12 m-0 p-0">
      <PrimeReactModule.Splitter className="h-screen w-full">
        <PrimeReactModule.SplitterPanel
          className="p-0 pt-0"
          minSize={10}
          size={50}
        >
          <div className="h-full surface-200">
            <PDfEditor
              id={folderId}
              ref={childRef}
              dataSet={dataSet}
              updateAbstractedValue={updateAbstractedValue}
            />
          </div>
        </PrimeReactModule.SplitterPanel>
        <PrimeReactModule.SplitterPanel
          size={50}
          minSize={20}
          className="overflow-y-auto overflow-x-hidden"
        >
          <div className="sticky top-0 z-5">
            <div className="w-full p-1 border-1 justify-content-between surface-border flex bg-white">
              <div>
                {/* <PrimeReactModule.Button
                  label="Start Abstract"
                  className="ml-1 px-1"
                  disabled={isAbstraction}
                  style={{
                    height: "31px",
                  }}
                  raised
                  ref={buttonConfirmEl}
                  onClick={() => startAbstraction()}
                /> */}
                <PrimeReactModule.Button
                  icon={"pi " + (isLabel ? "pi-plus" : "pi-minus")}
                  tooltip={isLabel ? "Expand" : "collapse"}
                  tooltipOptions={{ position: "bottom" }}
                  onClick={expandAll}
                  className="ml-1"
                  style={{
                    height: "31px",
                  }}
                  severity="secondary"
                  raised
                />
              </div>
              <span className="m-auto text-sm font-bold">
                {currentfoldername}
              </span>
              <div className="flex justify-content-center">
                {/* </div> */}
                <PrimeReactModule.Button
                  severity="success"
                  icon="pi pi-file-export"
                  tooltip="Export"
                  tooltipOptions={{ position: "bottom" }}
                  onClick={() => setVisible(true)}
                  className="mr-1"
                  style={{
                    height: "31px",
                  }}
                  raised
                />
                <PrimeReactModule.Button
                  onClick={() => navigate("/cognicraft")}
                  icon="pi pi-arrow-left"
                  tooltip="Back"
                  tooltipOptions={{ position: "bottom" }}
                  style={{
                    height: "31px",
                    boxShadow:
                      "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset",
                  }}
                ></PrimeReactModule.Button>
              </div>
            </div>
            <div className="template-summary p-0 m-0 w-full">
              <div className="grid card border-solid border-1 mb-0 py-2">
                {showDetails && (
                  <>
                    <div className="formgrid grid">
                      <div className="field col-12 m-0">
                        <label htmlFor="template">
                          Template : <span>{templateName}</span>{" "}
                        </label>
                      </div>
                    </div>
                    <div className="grid w-full mt-3">
                      <div className="col-6">
                        <span className="p-float-label">
                          <PrimeReactModule.Dropdown
                            value={selectModule}
                            options={dataSet}
                            optionLabel="sectionName"
                            filter
                            className="w-full"
                            onChange={(e) => selectModuleField(e)}
                          />
                          <label htmlFor="dd-city">Filter by module</label>
                        </span>
                      </div>
                      <div className="col-6">
                        <span className="p-float-label">
                          <PrimeReactModule.Dropdown
                            value={setField}
                            options={getField}
                            filter
                            optionLabel="label"
                            optionValue="fieldId"
                            className="w-full"
                            onChange={(e) => selectField(e)}
                          />
                          <label htmlFor="dd-city">Filter by field</label>
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className="flex justify-content-end flex-wrap">
                <button
                  className={
                    "text-center mr-5 summry-btn border-none outline-0 text-50 cursor-pointer"
                  }
                  onClick={toggleDetails}
                >
                  <i
                    className={
                      (showDetails ? "pi-chevron-up" : "pi-chevron-down") +
                      " pi"
                    }
                  ></i>
                </button>
              </div>
            </div>
          </div>
          {dataSet.length > 0 ? (
            <>
              <Module
                onTriggerEvent={handleEventjumpToPage}
                ref={useref}
                dataSet={dataSet}
                msg={msgs}
                prog={prog}
                show={show}
                setDataSet={setDataSet}
                permission={"Edit"}
              />
            </>
          ) : (
            <PrimeReactModule.Messages className="px-6" ref={msgs} />
          )}
          <PrimeReactModule.ScrollTop
            target="parent"
            threshold={150}
            className="w-2rem h-2rem border-round scroll-arrow border-circle bg-primary p-4"
            icon="pi pi-arrow-up text-base"
          />
        </PrimeReactModule.SplitterPanel>
      </PrimeReactModule.Splitter>
      <PrimeReactModule.Dialog
        header="Export Options"
        visible={visible}
        position={"top"}
        style={{ width: "49vw", top: "20px" }}
        onHide={() => setVisible(false)}
        footer={
          <div>
            <PrimeReactModule.Button
              label="Cancel"
              icon="pi pi-check"
              severity="secondary"
              onClick={() => setVisible(false)}
              autoFocus
            />
            <PrimeReactModule.Button
              label="Export"
              icon="pi pi-file-export"
              onClick={() => exportToExcel(foldername)}
            />
          </div>
        }
        draggable={false}
        resizable={false}
      >
        <div className="grid">
          <div className="col-12">
            <div className="field grid">
              <label
                htmlFor="firstname4"
                className="col-12 mb-2 md:col-3 md:mb-0"
              >
                Report Title :
              </label>
              <div className="col-12 md:col-9 p-fluid">
                <PrimeReactModule.InputText value={foldername} />
              </div>
            </div>
            <div className="mb-3">
              <label className="mt-3">Format :</label>
            </div>
            <div className="flex flex-column gap-3 ml-4">
              {categories?.map((category: any) => {
                return (
                  <div key={category.key} className="flex align-items-center">
                    <PrimeReactModule.RadioButton
                      inputId={category.key}
                      name="category"
                      value={category}
                      onChange={(e) => setSelectedCategory(e.value)}
                      checked={selectedCategory?.key === category.key}
                    />
                    <span className="ml-3">
                      <input
                        type="image"
                        src={category.Image}
                        width={35}
                        height={30}
                        alt={category.key}
                        className="mr-2"
                      />
                    </span>
                    <label htmlFor={category.key} className="ml-2">
                      {category.name}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </PrimeReactModule.Dialog>
      {showSpinner && <AiSpinner text="Abstracting..."></AiSpinner>}
    </div>
  );
};

export default Abstraction;
