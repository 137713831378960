import { useEffect, useState } from "react";
import { http } from "../../../../services/http.service";
import PrimeReactModule from "../../../../utils/context/PrimeReact";
import { Dialog } from "./../../../../components";
import * as Yup from "yup";
import { useFormik } from "formik";
import { classNames } from "primereact/utils";

const Template = ({ show }: any) => {
  const [visible, setVisible] = useState<any>(false);
  const [updateVisible, setUpdateVisible] = useState<any>(false);
  const [updateTempName, setUpdateTempName] = useState<any>();
  const [updateChecked, setUpdateChecked] = useState<any>(true);
  const [tempId, settempId] = useState<any>();
  const [checked, setChecked] = useState<any>(true);
  const [getTemp, setgetTemp] = useState<any>([]);

  useEffect(() => {
    getTemplate();
  }, []);

  const getTemplate = () => {
    http.get("template/data").then((res: any) => {
      if (res) {
        setgetTemp(res?.data);
      }
    });
  };

  const updateFooterContent = (
    <div>
      <PrimeReactModule.Button
        label="Cancel"
        onClick={() => setUpdateVisible(false)}
        className="p-button-secondary"
      />
      <PrimeReactModule.Button
        label="Update"
        onClick={() => UpdateTemplate()}
        autoFocus
      />
    </div>
  );

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isValid,
    resetForm,
  }: any = useFormik({
    initialValues: {
      templateName: "",
    },
    validationSchema: Yup.object({
      templateName: Yup.string().required("Template Name is required"),
    }),
    onSubmit: async (data: any) => {
      
      var body: any = {
        templateName: data.templateName,
        hasTemplate: checked,
      };
      http.post("template/data", body).then((res: any) => {
        if (res?.code === 201) {
          show("Success", res.data, "success");
          getTemplate();
          setVisible(false);
          resetForm();
        }
      });
    },
  });

  const footerContent = (
    <div>
      <PrimeReactModule.Button
        label="Cancel"
        onClick={() => {
          setVisible(false);
          resetForm();
        }}
        className="p-button-secondary"
      />
      <PrimeReactModule.Button
        label="Add"
        disabled={!isValid}
        onClick={() => handleSubmit()}
        autoFocus
      />
    </div>
  );

  const UpdateTemplate = () => {
    var body: any = {
      templateName: updateTempName,
      hasTemplate: updateChecked,
    };
    http.put("template/update/" + tempId, body).then((res: any) => {
      if (res?.code === 200) {
        show("Success", res.data, "success");
        getTemplate();
        setUpdateVisible(false);
      }
    });
  };

  const updateTempBody = (options: any) => {
    return (
      <div>
        <PrimeReactModule.Button
          icon="pi pi-pencil"
          tooltip="Edit"
          tooltipOptions={{ position: "top" }}
          className="text-green-600 bg-white border-none"
          style={{ fontSize: "1rem", cursor: "pointer" }}
          onClick={() => rowEdit(options)}
        ></PrimeReactModule.Button>
        <PrimeReactModule.Button
          icon="pi pi-trash"
          tooltip="Delete"
          tooltipOptions={{ position: "top" }}
          className="text-red-600 bg-white border-none"
          style={{ fontSize: "1rem", cursor: "pointer" }}
          onClick={() => deleteTemp(options)}
        ></PrimeReactModule.Button>
      </div>
    );
  };

  const deleteTemp = (options: any) => {
    http.delete("template/update/" + options.templateId).then((res: any) => {
      if (res.code === 200) {
        getTemplate();
        show(res.message, res.data, "success");
      }
    });
  };

  const rowEdit = (options: any) => {
    setUpdateTempName(options.templateName);
    setUpdateChecked(options.hasTemplate);
    settempId(options.templateId);
    setUpdateVisible(true);
  };

  return (
    <>
      <div className="grid m-0">
        <div className="col-12 text-right">
          <PrimeReactModule.Button
            label="Add"
            onClick={() => setVisible(true)}
          />
        </div>
        <div className="col-12 pt-0">
          <PrimeReactModule.DataTable
            scrollable
            scrollHeight="calc(100vh - 410px)"
            size="small"
            showGridlines
            paginator
            rows={25}
            responsiveLayout="scroll"
            rowsPerPageOptions={[5, 10, 25, 50]}
            value={getTemp}
            editMode="row"
            dataKey="templateId"
            tableStyle={{ minWidth: "50rem" }}
            globalFilterFields={["fieldName", "prompt"]}
            emptyMessage="No Field's Found."
          >
            {/* <PrimeReactModule.Column
              sortable
              field="templateId"
              header="Template ID"
              style={{ width: "20%" }}
            ></PrimeReactModule.Column> */}
            <PrimeReactModule.Column
              sortable
              field="templateName"
              header="Template Name"
              style={{ width: "20%" }}
            ></PrimeReactModule.Column>
            <PrimeReactModule.Column
              header="Status"
              // field="hasTemplate"
              align={"center"}
              className="text-center"
              body={(option)=>{
                return<>
                {option.hasTemplate?"Yes":"No"}
                </>
              }}
              style={{ width: "20%" }}
            ></PrimeReactModule.Column>
            <PrimeReactModule.Column
              header="Action"
              body={updateTempBody}
              align={"center"}
              style={{ width: "20%" }}
            ></PrimeReactModule.Column>
          </PrimeReactModule.DataTable>
        </div>
      </div>
      <Dialog
        maximizable={false}
        headername={"Add"}
        visible={visible}
        footer={footerContent}
        setVisible={() => setVisible(false)}
      >
        <div className="formgrid grid">
          <div className="field col-12 md:col-6">
            <label htmlFor="name">
              Template Name <span className="text-red-700">*</span>
            </label>
            <PrimeReactModule.InputText
              id="name"
              name="templateName"
              autoComplete="off"
              value={values.templateName}
              onBlur={handleBlur}
              onChange={handleChange}
              className={`mt-1 w-full ${classNames({
                "p-invalid": touched.templateName && errors.templateName,
              })}`}
            />
            {errors.templateName && touched.templateName && (
              <small className="p-error text-xs">{errors.templateName}</small>
            )}
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="city">Status</label>
            <PrimeReactModule.Checkbox
              className="pt-5"
              onChange={(e) => setChecked(e.checked)}
              checked={checked}
            ></PrimeReactModule.Checkbox>
          </div>
        </div>
      </Dialog>
      <Dialog
        maximizable={false}
        headername={"Update"}
        visible={updateVisible}
        footer={updateFooterContent}
        setVisible={() => setUpdateVisible(false)}
      >
        <div className="formgrid grid">
          <div className="field col-12 md:col-6">
            <label htmlFor="name">
              Template Name <span className="text-red-700">*</span>
            </label>
            <PrimeReactModule.InputText
              id="name"
              name="name"
              autoComplete="off"
              value={updateTempName}
              onChange={(e) => setUpdateTempName(e.target.value)}
              className="w-full"
            />
          </div>
          <div className="field col-12 md:col-6">
            <label htmlFor="city">Status</label>
            <PrimeReactModule.Checkbox
              className="pt-5"
              onChange={(e) => setUpdateChecked(e.checked)}
              checked={updateChecked}
            ></PrimeReactModule.Checkbox>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default Template;
