import "./../../../../styles/pdf.scss";
import '@react-pdf-viewer/core/lib/styles/index.css';
import { Worker } from '@react-pdf-viewer/core';
import { version } from "pdfjs-dist/legacy/build/pdf";
import PdfViewer, { ChildMethods } from "../../../../components/PdfEditor/ViewerComponent";
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { BASE_URL } from "./../../../../services/http.service";
import emitter from "./../../../../utils/context/Emitter/eventEmitter";
import { useParams } from "react-router-dom";

interface ChildProps {
    id: any,
    dataSet: any,
    updateAbstractedValue: (msg: string, valueId: number) => void
}

const PDfEditor: React.ForwardRefRenderFunction<{ callMethodJumpToPageOnCall: (pageId: any, keyword: any) => void } | null, ChildProps> = (props, ref) => {
    const { DocumentId } = useParams();
    const { id, dataSet, updateAbstractedValue } = props;
    const [pdfFile, setPdfFile] = React.useState<string>(''); // Assuming you want to store the data URL
    const pdfViwerRef = useRef<ChildMethods>(null);

    useImperativeHandle(ref, () => ({
        callMethodJumpToPageOnCall: (pageId: any, keyword: any) => {
            pdfViwerRef.current?.setValue(pageId, keyword)
        },
    }));


    useEffect(() => {
        const url = initUrl(Number(DocumentId));
        convertPdfToDataURL(url);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const initUrl = (DocumentId: number) => {
        return BASE_URL + "pdf/" + DocumentId
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const convertPdfToDataURL = async (url: string) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            const blob = await response.blob();
            const reader = new FileReader();

            reader.onloadend = () => {
                setPdfFile(reader.result as string);
                emitter.emit('StartTimer', "");
            };
            reader.readAsDataURL(blob);
        } catch (error) {
            console.error('Error fetching or converting PDF:', error);
        }
    };

    return (
        <>
            <div className="custom-pdf-editor">
                <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${version}/build/pdf.worker.min.js`}>
                    <div className="custom-pdf-editor__view">
                        {pdfFile &&
                            <PdfViewer ref={pdfViwerRef} fileUrl={pdfFile} id={id} dataSet={dataSet} updateAbstractedValue={updateAbstractedValue} areas={[]} />}
                    </div>
                </Worker>
            </div>
        </>
    )
}

export default forwardRef(PDfEditor);
