import PrimeReactModule from "./../../../../../../../../utils/context/PrimeReact";
import { useEffect, useState } from "react";
import { http } from "./../../../../../../../../services/http.service";
import { useParams } from "react-router-dom";
import DataNavigator from "../../../../../../../../components/DataNavigator";
import React from "react";

const Values = (props: any) => {
  var {
    item,
    data,
    val,
    setDataSet,
    prog,
    show,
    onTriggerEvent,
    permission,
    keywords,
    pageIndex,
  }: any = props;
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { DocumentId, templateId } = useParams();
  const [isTextArea, setIsTextArea] = useState<any>("");

  useEffect(() => {
    document.title = "File - CogniCraft";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getdata = async () => {
    await http
      .get(
        "get/templatedata/" +templateId+"/"+ DocumentId 
      )
      .then((res: any) => {
        if (res) {
          setDataSet(res.data.form);
        }
      });
    prog(100);
  };

  const handleTextareaChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    setIsTextArea(newValue);
  };

  const handleEditClick = (label: any, val: any) => {
    if (permission === "Edit") {
      if (val !== null || val !== "") {
        onTriggerEvent(Number(pageIndex[0]) - 1, keywords);
      }
      data.field = data.field.map((element: any) => ({
        ...element,
        isEdit: false,
      }));
      data.field.some((item: any) => {
        if (item.label === label) {
          item.isEdit = true;
          setIsTextArea(item.value);
          return true;
        }
        return false;
      });
      setDataSet([data])
    }
  };

  const handleCancelClick = (label: any) => {
    const updatedData = { ...data };
    const updatedItems = updatedData.field.find(
      (item: any) => item.label === label
    );
    if (updatedItems) {
      updatedItems.isEdit = false;
      setIsButtonDisabled(false);
    }
    data = updatedData;
    setDataSet([data])
  };

  const handleUpdatePrompt = async (formId: any, formValues: any) => {
    prog(50);
    var body: any = {
      documentId: Number(DocumentId),
      templateId: Number(templateId),
      formId: formId,
      fieldId: formValues.fieldId,
      valueId: formValues.valueId,
      value: isTextArea,
    };
    await http
      .put("update/document/form/field/value", body)
      .then((res: any) => {
        if (res) {
          if (res.code === 400) {
            show(res.message, res.data, "error");
          } else {
            show(res.message, res.data, "success");
            getdata();
          }
          setIsButtonDisabled(false);
        } else {
          prog(50);
        }
      });
  };

  const handleDeletePrompt = async (formId: any, formValues: any) => {
    prog(50);
    var bodys: any = {
      documentId: Number(DocumentId),
      templateId: Number(templateId),
      formId: formId,
      fieldId: formValues.fieldId,
      valueId: formValues.valueId,
    };
    await http.put("delete/document/form/value", bodys).then((res: any) => {
      if (res) {
        getdata();
        show(res.message, res.data, "success");
      } else {
        prog(100);
      }
    });
  };

  const handleNavigation = (currentValue: any) => {
    if (val) {
      onTriggerEvent(currentValue, keywords);
    }
  };

  return (
    <>
      {item.isEdit ? (
        <div className="col-11">
          <PrimeReactModule.InputTextarea
            spellCheck={true}
            rows={Math.max(3, Math.ceil(isTextArea?.length / 80))}
            id={item.label}
            className="mt-2 abstract-textarea text-base word-break-word-spacing text-justify block w-full"
            value={isTextArea}
            onChange={handleTextareaChange}
          />
        </div>
      ) : (
        <ul className="ListItems col-11">
          <li
            className="text-justify word-break-word-spacing cursor-pointer"
            onDoubleClick={() => {
              handleEditClick(item.label, val);
            }}
          >
            <p>
              {val &&
                val.split("\n").map((point: any, index: any) => (
                  <React.Fragment key={index}>
                    {index > 0 && <br />}
                    {point}
                  </React.Fragment>
                ))}
            </p>
          </li>
        </ul>
      )}
      {!item.isEdit ? (
        <div className="col-1 flex flex-column align-items-center p-0">
          <PrimeReactModule.Button
            icon="pi pi-pencil"
            disabled={permission !== "Edit" ? true : isButtonDisabled}
            onClick={() => handleEditClick(item.label, val)}
            rounded
            text
            aria-label="Filter"
          />
          <PrimeReactModule.Button
            icon="pi pi-trash"
            disabled={permission !== "Edit" ? true : isButtonDisabled}
            onClick={() => handleDeletePrompt(data.sectionId, item)}
            severity="danger"
            rounded
            text
            aria-label="Filter"
          />
        </div>
      ) : (
        <div className="col-1 flex flex-column align-items-center p-0">
          <PrimeReactModule.Button
            icon="pi pi-check"
            rounded
            text
            onClick={() => handleUpdatePrompt(data.sectionId, item)}
            aria-label="Filter"
          />
          <PrimeReactModule.Button
            icon="pi pi-times"
            onClick={() => handleCancelClick(item.label)}
            severity="danger"
            rounded
            text
            aria-label="Filter"
          />
        </div>
      )}
      {pageIndex && (
        <>
          {pageIndex.length > 0 && (
            <DataNavigator
              data={pageIndex}
              val={val}
              onNavigate={handleNavigation}
            />
          )}
        </>
      )}
    </>
  );
};

export default Values;
