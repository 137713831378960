import { useEffect, useState } from "react";
import { RouterProps } from "../../utils/modals/Models.interface";
import Abstraction from "./Abstraction";
import { useNavigate, useParams } from "react-router-dom";
import { http } from "../../services/http.service";
import PrimeReactModule from "../../utils/context/PrimeReact";
import ConfirmDialog from "../../components/ConfirmDialog";
import UploadingLoader from "../../utils/context/UploadingLoader";

const DocumentAbstraction: React.FC<RouterProps> = ({
  show,
  progressRef,
  prog,
}) => {
  const { DocumentId, templateId } = useParams();
  const [foldername, setFoldername] = useState<string>("");
  const [templateName, setTemplateName] = useState<string>("");
  const [isLoadAbstraction, setLoadAbstraction] = useState<boolean>(true);
  const [isAbstraction, setAbstraction] = useState<boolean>(true);
  const [isPdfHasOcred, setPdfHasOcred] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchDataInit();
    // checkPdfHasOcredInit();
    // progressRef.current.complete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // HandleUserWorkFlow start
  // const checkPdfHasOcredInit = async () => {
  //     let body = {
  //         "documentId": Number(documentId),
  //         "templateId": Number(templateId),
  //     }
  //     await http.post("check/pdf/has/ocr", body).then((res: any) => {
  //         if (res.code === 200) {
  //             if (res.data) {
  //                 fetchDataInit();
  //             } else {
  //                 setPdfHasOcred(true);
  //             }
  //         }
  //     })
  // };

  const fetchDataInit = async () => {
    
    // let body = {
    //   documentId: Number(DocumentId),
    //   templateId: Number(templateId),
    // };
    // setLoadAbstraction(false);
    await http
      .get("document/data/" + templateId + "/" + DocumentId)
      .then((res: any) => {
        if (res) {
          setFoldername(res.data.documentName);
          setTemplateName(res.data.templateName);
          //   setAbstraction(res.data.abstraction);
          setLoadAbstraction(false);
        }
      });
  };
  // HandleUserWorkFlow end

  const skeletonLoad = () => {
    return (
      <PrimeReactModule.Skeleton className="h-screen p-2">
        <div className="grid h-full m-0 p-0">
          <div className="col-6 card m-0">
            <PrimeReactModule.Skeleton
              width="100%"
              height="100%"
              className="p-2"
            >
              <div className="flex w-full h-full flex-column">
                <PrimeReactModule.Skeleton className="surface-50 w-full h-full my-2"></PrimeReactModule.Skeleton>
                <PrimeReactModule.Skeleton className="surface-50 w-full h-full my-2"></PrimeReactModule.Skeleton>
                <PrimeReactModule.Skeleton className="surface-50 w-full h-full my-2"></PrimeReactModule.Skeleton>
                <PrimeReactModule.Skeleton className="surface-50 w-full h-full my-2"></PrimeReactModule.Skeleton>
                <PrimeReactModule.Skeleton className="surface-50 w-full h-full my-2"></PrimeReactModule.Skeleton>
                <PrimeReactModule.Skeleton className="surface-50 w-full h-full my-2"></PrimeReactModule.Skeleton>
                <PrimeReactModule.Skeleton className="surface-50 w-full h-full my-2"></PrimeReactModule.Skeleton>
                <PrimeReactModule.Skeleton className="surface-50 w-full h-full my-2"></PrimeReactModule.Skeleton>
                <PrimeReactModule.Skeleton className="surface-50 w-full h-full my-2"></PrimeReactModule.Skeleton>
                <PrimeReactModule.Skeleton className="surface-50 w-full h-full my-2"></PrimeReactModule.Skeleton>
                <PrimeReactModule.Skeleton className="surface-50 w-full h-full my-2"></PrimeReactModule.Skeleton>
                <PrimeReactModule.Skeleton className="surface-50 w-full h-full my-2"></PrimeReactModule.Skeleton>
                <PrimeReactModule.Skeleton className="surface-50 w-full h-full my-2"></PrimeReactModule.Skeleton>
                <PrimeReactModule.Skeleton className="surface-50 w-full h-full my-2"></PrimeReactModule.Skeleton>
                <PrimeReactModule.Skeleton className="surface-50 w-full h-full my-2"></PrimeReactModule.Skeleton>
                <PrimeReactModule.Skeleton className="surface-50 w-full h-full my-2"></PrimeReactModule.Skeleton>
                <PrimeReactModule.Skeleton className="surface-50 w-full h-full my-2"></PrimeReactModule.Skeleton>
                <PrimeReactModule.Skeleton className="surface-50 w-full h-full my-2"></PrimeReactModule.Skeleton>
              </div>
            </PrimeReactModule.Skeleton>
          </div>
          <div className="col-6 card m-0">
            <PrimeReactModule.Skeleton
              width="100%"
              height="100%"
              className="p-2"
            >
              <PrimeReactModule.Skeleton className="surface-50 w-full h-4rem"></PrimeReactModule.Skeleton>
              <PrimeReactModule.Skeleton className="surface-50 w-full h-2rem mt-1"></PrimeReactModule.Skeleton>
              <div className="w-full h-full">
                <PrimeReactModule.Skeleton className="surface-100 w-full h-3rem mt-4"></PrimeReactModule.Skeleton>
                <div className="px-5 h-full">
                  <div className="border-round surface-100 p-4">
                    <ul className="m-0 p-0 list-none">
                      <li className="mb-3">
                        <div className="flex">
                          <div style={{ flex: "1" }}>
                            <PrimeReactModule.Skeleton
                              width="75%"
                              className="mb-2"
                            ></PrimeReactModule.Skeleton>
                            <PrimeReactModule.Skeleton width="100%"></PrimeReactModule.Skeleton>
                          </div>
                        </div>
                      </li>
                      <li className="mb-3">
                        <div className="flex">
                          <div style={{ flex: "1" }}>
                            <PrimeReactModule.Skeleton
                              width="75%"
                              className="mb-2"
                            ></PrimeReactModule.Skeleton>
                            <PrimeReactModule.Skeleton width="100%"></PrimeReactModule.Skeleton>
                          </div>
                        </div>
                      </li>
                      <li className="mb-3">
                        <div className="flex">
                          <div style={{ flex: "1" }}>
                            <PrimeReactModule.Skeleton
                              width="75%"
                              className="mb-2"
                            ></PrimeReactModule.Skeleton>
                            <PrimeReactModule.Skeleton width="100%"></PrimeReactModule.Skeleton>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="flex">
                          <div style={{ flex: "1" }}>
                            <PrimeReactModule.Skeleton
                              width="75%"
                              className="mb-2"
                            ></PrimeReactModule.Skeleton>
                            <PrimeReactModule.Skeleton width="100%"></PrimeReactModule.Skeleton>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </PrimeReactModule.Skeleton>
          </div>
        </div>
      </PrimeReactModule.Skeleton>
    );
  };

  const accept = async () => {
    prog(50);
    setShowSpinner(true);
    let body = {
      documentId: Number(DocumentId),
    };
    await http.post("start/pdf/merge/do/ocr", body).then((res: any) => {
      if (res.code === 200) {
        if (res.data) {
          prog(100);
          fetchDataInit();
          setPdfHasOcred(false);
          setShowSpinner(false);
        } else {
          prog(100);
        }
      }
    });
  };

  const conFirmFolderFooter = (
    <div>
      <PrimeReactModule.Button
        label="Cancel"
        onClick={() => navigate(-1)}
        className="p-button-secondary"
      />
      <PrimeReactModule.Button label="Check OCR" onClick={accept} autoFocus />
    </div>
  );

  return (
    <>
      {/* {!isPdfHasOcred && */}
      <>
        {isLoadAbstraction ? (
          skeletonLoad()
        ) : (
          <Abstraction
            show={show}
            progressRef={progressRef}
            prog={prog}
            folderId={Number(DocumentId)}
            templateId={Number(templateId)}
            foldername={foldername}
            templateName={templateName}
            isAbstraction={isAbstraction}
            fetchDataInit={fetchDataInit}
          />
        )}
      </>
      {/* } */}
      <ConfirmDialog
        visible={isPdfHasOcred}
        conFirmFolderFooter={conFirmFolderFooter}
        headername="Requisite"
        message="To proceed further, Required to check OCR"
        setVisible={() => setPdfHasOcred(false)}
      />
      {showSpinner && <UploadingLoader text="Check OCR..."></UploadingLoader>}
    </>
  );
};

export default DocumentAbstraction;
