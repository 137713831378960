// set from local start
const setAuthUser = (value: any) => {
    sessionStorage.setItem("authuser", JSON.stringify(value));
}
// set from local end

// get from local start
const getAuthUser = () => {
    return JSON.parse(String(sessionStorage.getItem('authuser')));
}
// get from local end

// is key avilable from local start
const isAuthUser = () => {
    return JSON.parse(String(sessionStorage.getItem("authuser")));
}
// is key avilable from local end

// clear local start
const removeAll = () => {
    sessionStorage.clear();
}
// clear local end

export {
    setAuthUser,
    getAuthUser,
    isAuthUser,
    removeAll,
} 