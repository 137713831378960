import { Button, LoadError, PageChangeEvent, PageLayout, Position, PrimaryButton, ProgressBar, SpecialZoomLevel, Tooltip, Viewer } from '@react-pdf-viewer/core';
import { ToolbarProps, defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import type { ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import ReadingIndicatorPluginComp from './../display-reading-progress-at-the-top/ReadingIndicatorPlugin';
import { highlightPlugin, MessageIcon, Trigger } from '@react-pdf-viewer/highlight';
import type { HighlightArea, RenderHighlightContentProps, RenderHighlightsProps, RenderHighlightTargetProps } from '@react-pdf-viewer/highlight';
import { searchPlugin } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import JumpToPage from '../jumpToPagePlugin';
import PrimeReactModule from '../../../utils/context/PrimeReact';

interface PdfViewerProps {
    areas: HighlightArea[];
    fileUrl: string;
    id: number;
    dataSet: any;
    updateAbstractedValue: (msg: string, valueId: number) => void
}

export interface ChildMethods {
    setValue: (pageIndex: number, keyword: string[]) => void;
}

interface Note {
    id: number;
    content: string;
    highlightAreas: HighlightArea[];
    quote: string;
}

const PdfViewer: React.ForwardRefRenderFunction<ChildMethods, PdfViewerProps> = (props, ref) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { areas, fileUrl, dataSet, updateAbstractedValue } = props;
    const readingIndicatorPluginInstance = ReadingIndicatorPluginComp();
    const { ReadingIndicator } = readingIndicatorPluginInstance;
    const jumpToPagePluginInstance = JumpToPage();
    const { jumpToPage } = jumpToPagePluginInstance;
    const [selectModule, setselectModule] = useState<any>(null);
    const [getField, setgetField] = useState<any>(null);
    const [setField, setSetField] = useState<any>(null);
    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [valueId, setValueId] = useState<number>(0);
    const [message, setMessage] = React.useState('test');
    const [notes, setNotes] = React.useState<Note[]>([]);
    let noteId = notes.length;

    const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
        ...slot,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        Print: () => <></>,
        PrintMenuItem: () => <></>,
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
    });

    const initialPage = sessionStorage.getItem('current-page') ? parseInt(String(sessionStorage.getItem('current-page')), 10) : 0;

    const pageLayout: PageLayout = {
        transformSize: ({ size }) => ({
            height: size.height + 30,
            width: size.width + 30,
        }),
    };

    const onDocumentRender = (error: any) => {
    }

    const renderError = (error: LoadError) => {
        let message = '';
        switch (error.name) {
            case 'InvalidPDFException':
                message = 'The document is invalid or corrupted';
                break;
            case 'MissingPDFException':
                message = 'The document is missing';
                break;
            case 'UnexpectedResponseException':
                message = 'Unexpected server response';
                break;
            default:
                message = 'Cannot load the document';
                break;
        }

        return (
            <div
                style={{
                    alignItems: 'center',
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'center',
                }}
            >
                <div
                    style={{
                        backgroundColor: '#e53e3e',
                        borderRadius: '0.25rem',
                        color: '#fff',
                        padding: '0.5rem',
                    }}
                >
                    {message}
                </div>
            </div>
        );
    };

    const handlePageChange = (e: PageChangeEvent) => {
        sessionStorage.setItem('current-page', `${e.currentPage}`);
    };

    const renderToolbar = React.useCallback(
        (Toolbar: (props: ToolbarProps) => React.ReactElement) => (
            <>
                <Toolbar>
                    {renderDefaultToolbar(transform)}
                </Toolbar>
                <div
                    style={{
                        bottom: '-0.25rem',
                        position: 'absolute',
                        left: 0,
                        // Take the full width of toolbar
                        width: '100%',
                    }}
                >
                    <ReadingIndicator />
                </div>
            </>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const renderHighlightTarget = (props: RenderHighlightTargetProps) => {
        return (
            <div
                style={{
                    background: '#eee',
                    display: 'flex',
                    position: 'absolute',
                    left: `${props.selectionRegion.left}%`,
                    top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
                    transform: 'translate(0, 8px)',
                    zIndex: 1,
                }}
            >
                <Tooltip
                    position={Position.TopCenter}
                    target={
                        <Button onClick={props.toggle}>
                            <MessageIcon />
                        </Button>
                    }
                    content={() => <div style={{ width: '100px' }}>Add a note</div>}
                    offset={{ left: 0, top: -8 }}
                />
            </div>
        )
    };



    const renderHighlights = (props: RenderHighlightsProps) => (
        <div>
            {areas
                .filter((area) => area.pageIndex === props.pageIndex)
                .map((area, idx) => (
                    <div
                        className='pdf-highlight-underline'
                        onClick={() => alert("")}
                        key={idx}
                        style={Object.assign(
                            {},
                            {
                                cursor: 'pointer',
                                background: '#FFFF00',
                                opacity: 0.4,
                                zIndex: 99999,
                                textDecoration: 'underline'
                            },
                            props.getCssProperties(area, props.rotation)
                        )}
                    />
                ))}
            {/* {notes.map((note) => (
                <React.Fragment key={note.id}>
                    {note.highlightAreas.filter((area) => area.pageIndex === props.pageIndex)
                        .map((area, idx) => (
                            <div
                                onClick={() => alert("")}
                                key={idx}
                                style={Object.assign(
                                    {},
                                    {
                                        cursor: 'pointer',
                                        background: '#FFFF00',
                                        opacity: 0.4,
                                        zIndex: 99999,
                                        textDecoration: 'underline'
                                    },
                                    props.getCssProperties(area, props.rotation)
                                )}
                            />
                        ))}
                </React.Fragment>
            ))} */}
        </div>
    );


    useEffect(() => {
        if (selectedId !== null) {
            // Scroll to the corresponding accordion tab when selectedId changes
            const element = document.getElementById(`accordionTab_${selectedId}`);
            if (element) {
                element.scrollIntoView({ block: "center", behavior: "smooth" });
            }
            setSelectedId(0);
        }
    }, [selectedId]);

    const selectModuleField = (e: any) => {
        setselectModule(e.target.value);
        setgetField(e.target.value.field);
    };

    const selectField = (e: any) => {
        setSetField(e.target.value);
        setSelectedId(e.target.value);
        let filteredField: any = dataSet.filter((val: any) => val.sectionId === selectModule.sectionId);
        let filteredValue: any = filteredField[0].field.find((val: any) => val.fieldId === e.target.value);
        setValueId(filteredValue.valueId);
    };


    const renderHighlightContent = (props: RenderHighlightContentProps) => {
        const addNote = () => {
            if (message !== '') {
                const note: Note = {
                    id: ++noteId,
                    content: message,
                    highlightAreas: props.highlightAreas,
                    quote: props.selectedText.replace(/\s+/g, ' '),
                };
                setNotes(notes.concat([note]));
                props.cancel();
                updateAbstractedValue(note.quote.trim(), valueId);
                setselectModule(null);
                setSetField(null);
            }
        };

        return (
            <div
                style={{
                    background: '#fff',
                    border: '1px solid rgba(0, 0, 0, .3)',
                    borderRadius: '2px',
                    padding: '8px',
                    position: 'absolute',
                    left: `${props.selectionRegion.left}%`,
                    top: `${props.selectionRegion.top + props.selectionRegion.height}%`,
                    zIndex: 1,
                }}
            >
                <div className="grid">
                    <div className="col-12">
                        <PrimeReactModule.Dropdown
                            value={selectModule}
                            options={dataSet}
                            optionLabel="sectionName"
                            filter
                            className="w-full"
                            placeholder='Select a module'
                            onChange={(e) => selectModuleField(e)}
                        />
                    </div>
                    <div className="col-12">
                        <PrimeReactModule.Dropdown
                            value={setField}
                            options={getField}
                            filter
                            optionLabel="label"
                            optionValue="fieldId"
                            placeholder='Select a field'
                            className="w-full"
                            onChange={(e) => selectField(e)}
                        />
                    </div>
                </div>
                <div>
                    <textarea
                        rows={3}
                        style={{
                            border: '1px solid rgba(0, 0, 0, .3)',
                        }}
                        hidden
                        value={message}
                        onChange={(e) => setMessage("test")}
                    ></textarea>
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginTop: '8px',
                    }}
                >
                    <div style={{ marginRight: '8px' }}>
                        <PrimaryButton onClick={addNote}>Add</PrimaryButton>
                    </div>
                    <Button onClick={props.cancel}>Cancel</Button>
                </div>
            </div>
        );
    };


    const highlightPluginInstance = highlightPlugin({
        renderHighlightTarget,
        renderHighlightContent,
        renderHighlights,
        trigger: Trigger.TextSelection,
    });

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar, sidebarTabs: (defaultTabs: any) => [
            defaultTabs[0], // Thumbnails tab
        ],
    });

    useImperativeHandle(ref, () => ({
        setValue: setDocumentScroll,
    }));

    const searchPluginInstance = searchPlugin();

    const { setTargetPages, highlight } = searchPluginInstance;


    const setDocumentScroll = (pageId: number, keyword: string[]) => {
        if (keyword.length > 0) {
            jumpToPage(pageId);
            setTargetPages((pageFilter) => pageFilter.pageIndex === pageId);
            let searchKeyword: any[] = [];
            keyword.forEach(element => {
                searchKeyword.push({
                    keyword: element.replaceAll(" ", ''),
                    wholeWords: true,
                });
            });
            highlight(searchKeyword);
        }
    };

    const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

    return (
        <>
            <Viewer
                fileUrl={fileUrl}//pdf url
                pageLayout={pageLayout}//gap between pages 
                renderError={renderError} //rise error if something got issue
                renderLoader={(percentages: number) => (
                    <div style={{ width: '240px' }}>
                        <ProgressBar progress={Math.round(percentages)} />
                    </div>
                )}//loading
                onDocumentLoad={onDocumentRender}
                initialPage={initialPage}//set defafault page
                onPageChange={handlePageChange}
                defaultScale={SpecialZoomLevel.PageWidth}
                plugins={[defaultLayoutPluginInstance, readingIndicatorPluginInstance, highlightPluginInstance, jumpToPagePluginInstance, searchPluginInstance]}
            />;
        </>
    )
}

export default forwardRef(PdfViewer);
