// DataNavigator.jsx
import React, { useState } from 'react';
import styles from './../../styles/DataNavigator.module.css';

const DataNavigator: React.FC<any> = ({ data, onNavigate }: any) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToPrevious = () => {
        const newIndex = (currentIndex - 1 + data.length) % data.length;
        setCurrentIndex(newIndex);
        onNavigate(data[newIndex] - 1);
    };

    const goToNext = () => {
        const newIndex = (currentIndex + 1) % data.length;
        setCurrentIndex(newIndex);
        onNavigate(data[newIndex] - 1);
    };

    return (
        <div className={`${styles.dataNavigator} mt-1`} >
            <label>Page Navigation :&nbsp;&nbsp;</label>
            <button
                onClick={goToPrevious}
                disabled={currentIndex === 0}
                className={styles.navigationButton}
            >
                <i className='pi pi-angle-left'></i>
            </button>
            &nbsp;
            <button className={styles.data} onClick={() => onNavigate(data[currentIndex] - 1)}>{data[currentIndex]}</button>
            &nbsp;
            <button
                onClick={goToNext}
                disabled={currentIndex === data.length - 1}
                className={styles.navigationButton}
            >
                <i className='pi pi-angle-right'></i>
            </button>
        </div>
    );
};

export default DataNavigator;
