import React from "react";
import PrimeReactModule from "../../utils/context/PrimeReact";
interface ConfirmDialogDialogProps {
  headername: any;
  visible: boolean;
  message: any;
  setVisible: (value: boolean) => void;
  conFirmFolderFooter: React.ReactNode;
}

const ConfirmDialog: React.FC<ConfirmDialogDialogProps> = ({
  headername,
  visible,
  message,
  setVisible,
  conFirmFolderFooter
}) => {
  return (
    <>
      <PrimeReactModule.ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message={message}
        icon="pi pi-exclamation-triangle"
        header={headername}
        draggable={false}
        closable={false}
        footer={conFirmFolderFooter}
        dismissableMask={false}
      />
    </>
  );
};

export default ConfirmDialog;
