import React, { useEffect, useRef, useState } from "react";
import Dashboard from "../Dashboard";
import PrimeReactModule from "../../utils/context/PrimeReact";
import { Button } from "primereact/button";
import { Field } from "formik";
import { Form } from "react-router-dom";
import { Template, Prompt } from "../Masters/MastersTab";
import Masters from "../Masters";
import UploadingLoader from "../../utils/context/UploadingLoader";
import { http } from "../../services/http.service";
import SummarySkeleton from "../../utils/context/SummarySkeleton";
import { Chat, Abstraction } from "./../SummaryPanel";
import { getAuthUser, removeAll } from "../../services/getLocalData";
import AiSpinner from "../../utils/context/AiSpinner";
import { Link, useNavigate } from "react-router-dom";

const DocAssist = ({ show, progressRef, prog }: any) => {
  const childRef = useRef<any>(null);
  const abstractRef = useRef<any>(null);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [pdfShowSpinner, setPdfShowSpinner] = useState<boolean>(false);
  const [showAbstrpinner, setshowAbstrSpinner] = useState<boolean>(false);
  const [showContainer, setShowContainer] = useState<boolean>(true);
  const [showSummary, setshowSummary] = useState<boolean>(true);
  const [getSummary, setGetSummary] = useState<any>();
  const [getDocumentId, setDocumentId] = useState<any>();
  const [document, setDocument] = useState<any>();
  const [pdfId, setPdfId] = useState<any>();
  const navigate = useNavigate();
  const [isAbstractAllow, setIsAbstractAllow] = useState<any>();

  useEffect(() => {
    getApi();
  }, []);

  const checkAbstract = (DocumentId:any) => {
    var body: any = {
      documentId: DocumentId,
    };
    http.post("abract/data", body).then((res: any) => {
      setIsAbstractAllow(res?.data);
    });
  };

  const getApi = async () => {
    await http.get(`get/data/` + getAuthUser()?.userid).then((res: any) => {
      const filtered: any = res?.data?.filter(
        (user: any) => user.isOCR == true
      );
      setDocument(filtered);
    });
  };

  const showOcrSpinner = (val: any) => {
    setShowSpinner(val);
  };

  const showPdfSpinner = (val: any) => {
    setPdfShowSpinner(val);
  };

  const showAbstractSpinner = (val: any) => {
    setshowAbstrSpinner(val);
  };

  const isShoeSummanr = (val: any, documentId: any) => {
    setshowSummary(true);
    setPdfId(documentId);
    setDocumentId(documentId);
    setShowContainer(val);
    checkAbstract(documentId);
    setGetSummary([]);
    getApi();
    http
      .get("get/summary/" + documentId + "/" + getAuthUser()?.userid)
      .then((res: any) => {
        setGetSummary(res.data);
        setshowSummary(false);
      });
  };



  const changePdf = (e: any) => {
    setPdfId(e.target.value);
    setDocumentId(e.target.value);
    setGetSummary([]);
    checkAbstract(e.target.value);
    http
      .get("get/summary/" + e.target.value + "/" + getAuthUser()?.userid)
      .then((res: any) => {
        setGetSummary(res.data);
      });
    if (childRef?.current) {
      childRef.current.toggleCollapse(e);
    }
  };

  return (
    <div>
      <div className="grid overall_container px-8 pb-6 relative">
        <div className="col-12">
          {showContainer ? (
            <div className="containers">
              <Dashboard
                show={show}
                progressRef={progressRef}
                prog={prog}
                onShowSpinner={showOcrSpinner}
                onShowPdfSpinner={showPdfSpinner}
                getSummary={isShoeSummanr}
              ></Dashboard>
            </div>
          ) : (
            <div className="containers">
              <div className="grid align-items-center">
                <div className="col-12 flex align-items-center justify-content-between">
                  <h4 className="m-0">
                    <i
                      className="pi pi-arrow-left text-white border-circle	 bg-blue-400 p-2 mr-2"
                      onClick={() => setShowContainer(true)}
                    ></i>
                    Craft
                  </h4>
                  <div>
                    <PrimeReactModule.Dropdown
                      onChange={(e) => {
                        changePdf(e);
                      }}
                      filter
                      value={pdfId}
                      options={document}
                      optionLabel="docName"
                      optionValue="documentId"
                      placeholder="Select a Document"
                      style={{ width: "250px" }}
                    />
                    {/* <PrimeReactModule.Dropdown
                      onChange={(e) => {}}
                      className="ml-3"
                      filter
                      name="templateId"
                      optionLabel="sectionName"
                      filterBy="sectionName"
                      placeholder="Select a GPT"
                      style={{ width: "250px" }}
                    /> */}
                  </div>
                </div>
              </div>
              <PrimeReactModule.TabView>
                <PrimeReactModule.TabPanel
                  header="Summarization"
                  className="relative"
                >
                  <div className="">
                    {showSummary ? (
                      <SummarySkeleton></SummarySkeleton>
                    ) : (
                      <p className="text-justify p-3">{getSummary}</p>
                    )}
                  </div>
                  <div className="mt-3"></div>
                </PrimeReactModule.TabPanel>
                <PrimeReactModule.TabPanel header="Abstraction">
                  <Abstraction
                    DocumentId={getDocumentId}
                    ref={childRef}
                    isAbstractAllows={isAbstractAllow}
                    onShowSpinner={showAbstractSpinner}
                  ></Abstraction>
                  {/* <div className="grid m-0 mt-2">
                    <div className="field col-12 md:col-4">
                      <label htmlFor="name" className="pl-2">
                        Template Name <span className="text-red-700">*</span>
                      </label>
                      <PrimeReactModule.Dropdown
                        onChange={(e) => {}}
                        filter
                        name="templateId"
                        optionLabel="sectionName"
                        filterBy="sectionName"
                        placeholder="Select a Model"
                        style={{ width: "250px" }}
                        className="ml-2 w-full"
                      />
                    </div>
                    <div className="field col-12 md:col-4">
                      <label htmlFor="name" className="pl-2">
                        ChatGpt <span className="text-red-700">*</span>
                      </label>
                      <PrimeReactModule.Dropdown
                        onChange={(e) => {}}
                        filter
                        name="templateId"
                        optionLabel="sectionName"
                        filterBy="sectionName"
                        placeholder="Select a Model"
                        style={{ width: "250px" }}
                        className="ml-2 w-full"
                      />
                    </div>
                    <div className="col-12 text-right">
                      <PrimeReactModule.Button
                        type="submit"
                        className="msger-send-btn"
                      >
                        Abstarction
                      </PrimeReactModule.Button>
                    </div>
                  </div> */}
                </PrimeReactModule.TabPanel>
                <PrimeReactModule.TabPanel header="Copilot ">
                  <Chat DocumentId={getDocumentId} ref={childRef} show={show}></Chat>
                </PrimeReactModule.TabPanel>
                <PrimeReactModule.TabPanel header="Template">
                  <Masters
                    show={show}
                    progressRef={progressRef}
                    prog={prog}
                  ></Masters>
                </PrimeReactModule.TabPanel>
              </PrimeReactModule.TabView>
            </div>
          )}
          {/* <div className="containers">
            <Dashboard
              show={show}
              progressRef={progressRef}
              prog={prog}
              onShowSpinner={showOcrSpinner}
            ></Dashboard>
          </div> */}
        </div>
      </div>
      <div className="logout">
        <PrimeReactModule.Button
          icon="pi pi-sign-out"
          className="p-button-rounded align-items-center text-red-600 p-4 mr-3"
          raised
          text
          tooltip="Logout"
          tooltipOptions={{ position: "bottom" }}
          onClick={() => {
            removeAll();
            navigate("/login");
            // logout();
          }}
        ></PrimeReactModule.Button>
      </div>
      {/* <div className="sticky-social">
        <ul className="social text-center">
          <li className="fqa">
          
            <a href="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                shapeRendering="geometricPrecision"
                textRendering="geometricPrecision"
                imageRendering="optimizeQuality"
                fillRule="evenodd"
                clipRule="evenodd"
                viewBox="0 0 301 511.26"
                height={"20px"}
                fill="#fff"
              >
                <path
                  fillRule="nonzero"
                  d="M102.05 362.83v-4.57c.35-30.36 3.33-54.41 9.17-72.28 5.73-17.86 13.97-32.4 24.73-43.4 10.77-11.11 23.72-21.19 38.84-30.36 9.73-6.18 18.55-13.05 26.34-20.72 7.78-7.57 13.96-16.39 18.55-26.35 4.58-9.85 6.87-20.84 6.87-32.98 0-14.43-3.44-26.92-10.31-37.57-6.76-10.54-15.92-18.67-27.15-24.5-11.34-5.73-23.93-8.59-37.79-8.59-12.48 0-24.39 2.51-35.84 7.78-11.34 5.16-20.85 13.28-28.3 24.28-7.44 10.99-11.79 25.09-12.94 42.27H0c1.15-29.22 8.59-53.95 22.22-74.22 13.75-20.27 31.85-35.63 54.29-46.04C99.08 5.16 124.05 0 151.3 0c30 0 56.12 5.49 78.57 16.72 22.45 11.11 39.97 26.69 52.46 46.61C294.69 83.26 301 106.4 301 132.98c0 18.2-2.86 34.58-8.59 49.13-5.73 14.54-13.75 27.6-24.28 38.93-10.55 11.46-23.03 21.54-37.68 30.24-13.86 8.71-25.09 17.64-33.56 26.92-8.59 9.27-14.78 20.27-18.67 32.87-3.9 12.71-6.08 28.4-6.41 47.19v4.57h-69.76zm36.88 148.43c-13.39-.1-24.85-4.8-34.47-14.31-9.62-9.5-14.32-21.07-14.32-34.59 0-13.28 4.7-24.73 14.32-34.24 9.62-9.51 21.08-14.32 34.47-14.32 13.17 0 24.62 4.81 34.25 14.32 9.73 9.51 14.54 20.96 14.54 34.24 0 8.93-2.28 17.18-6.75 24.52-4.47 7.44-10.32 13.4-17.64 17.75-7.33 4.35-15.47 6.53-24.4 6.63z"
                />
              </svg>
            </a>
          </li>
          <li className="contact">
            <a href="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                shapeRendering="geometricPrecision"
                textRendering="geometricPrecision"
                imageRendering="optimizeQuality"
                fillRule="evenodd"
                clipRule="evenodd"
                viewBox="0 0 121 150.26"
                height={"20px"}
                fill="#fff"
              >
                <path
                  fillRule="nonzero"
                  d="M33.84,50.25c4.13,7.45,8.89,14.6,15.07,21.12c6.2,6.56,13.91,12.53,23.89,17.63c0.74,0.36,1.44,0.36,2.07,0.11 c0.95-0.36,1.92-1.15,2.87-2.1c0.74-0.74,1.66-1.92,2.62-3.21c3.84-5.05,8.59-11.32,15.3-8.18c0.15,0.07,0.26,0.15,0.41,0.21 l22.38,12.87c0.07,0.04,0.15,0.11,0.21,0.15c2.95,2.03,4.17,5.16,4.2,8.71c0,3.61-1.33,7.67-3.28,11.1 c-2.58,4.53-6.38,7.53-10.76,9.51c-4.17,1.92-8.81,2.95-13.27,3.61c-7,1.03-13.56,0.37-20.27-1.69 c-6.56-2.03-13.17-5.38-20.39-9.84l-0.53-0.34c-3.31-2.07-6.89-4.28-10.4-6.89C31.12,93.32,18.03,79.31,9.5,63.89 C2.35,50.95-1.55,36.98,0.58,23.67c1.18-7.3,4.31-13.94,9.77-18.32c4.76-3.84,11.17-5.94,19.47-5.2c0.95,0.07,1.8,0.62,2.25,1.44 l14.35,24.26c2.1,2.72,2.36,5.42,1.21,8.12c-0.95,2.21-2.87,4.25-5.49,6.15c-0.77,0.66-1.69,1.33-2.66,2.03 c-3.21,2.33-6.86,5.02-5.61,8.18L33.84,50.25L33.84,50.25L33.84,50.25z"
                />
              </svg>
            </a>
          </li>
          <li className="feedback">
            <a href="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                shapeRendering="geometricPrecision"
                textRendering="geometricPrecision"
                imageRendering="optimizeQuality"
                fillRule="evenodd"
                clipRule="evenodd"
                viewBox="0 0 120 120.26"
                height={"20px"}
                fill="#fff"
              >
                <path
                  fillRule="nonzero"
                  d="M64.44,61.11c1.79,0,3.12-1.45,3.12-3.12c0-1.78-1.45-3.12-3.12-3.12H24.75c-1.78,0-3.12,1.45-3.12,3.12 c0,1.78,1.45,3.12,3.12,3.12H64.44L64.44,61.11L64.44,61.11L64.44,61.11z M77.45,19.73l18.1-19.14c0.69-0.58,1.39-0.81,2.2-0.35 l14.56,14.1c0.58,0.69,0.69,1.5-0.12,2.31L93.75,36.14L77.45,19.73L77.45,19.73L77.45,19.73L77.45,19.73z M87.74,42.27l-18.66,3.86 l2.36-20.28L87.74,42.27L87.74,42.27z M19.14,13.09h41.73l-3.05,6.45H19.14c-3.48,0-6.65,1.43-8.96,3.73s-3.73,5.46-3.73,8.96 v45.74c0,3.48,1.43,6.66,3.73,8.96c2.3,2.3,5.47,3.73,8.96,3.73h3.72v0.01l0.21,0.01c1.77,0.12,3.12,1.66,2.99,3.43l-1.26,18.1 L48.78,97.7c0.58-0.58,1.38-0.93,2.27-0.93h37.32c3.48,0,6.65-1.42,8.96-3.73c2.3-2.3,3.73-5.48,3.73-8.96V50.45h6.68v42.69 c0.35,9.63-3.58,15.04-19.43,15.7l-32.25-0.74l-32.73,13.87l-0.16,0.13c-1.35,1.16-3.38,1-4.54-0.36c-0.57-0.67-0.82-1.49-0.77-2.3 l1.55-22.34h-0.26c-5.26,0-10.05-2.15-13.52-5.62C2.15,88.03,0,83.24,0,77.98V32.23c0-5.26,2.15-10.05,5.62-13.52 C9.08,15.24,13.87,13.09,19.14,13.09L19.14,13.09L19.14,13.09z M79.69,78.42c1.79,0,3.12-1.45,3.12-3.12 c0-1.79-1.45-3.12-3.12-3.12H24.75c-1.78,0-3.12,1.45-3.12,3.12c0,1.78,1.45,3.12,3.12,3.12H79.69L79.69,78.42L79.69,78.42 L79.69,78.42z M50.39,43.81c1.78,0,3.12-1.45,3.12-3.12c0-1.67-1.45-3.12-3.12-3.12H24.75c-1.78,0-3.12,1.45-3.12,3.12 c0,1.78,1.45,3.12,3.12,3.12H50.39L50.39,43.81L50.39,43.81L50.39,43.81z"
                />
              </svg>
            </a>
          </li>
          <li className="linkedin">
            <a href="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                shapeRendering="geometricPrecision"
                textRendering="geometricPrecision"
                imageRendering="optimizeQuality"
                fillRule="evenodd"
                clipRule="evenodd"
                viewBox="0 0 500 500.26"
                height={"20px"}
                fill="#fff"
              >
                <path
                  fillRule="nonzero"
                  d="M204.97 197.54h64.69v33.16h.94c9.01-16.16 31.04-33.16 63.89-33.16 68.31 0 80.94 42.51 80.94 97.81v116.92h-67.46l-.01-104.13c0-23.81-.49-54.45-35.08-54.45-35.12 0-40.51 25.91-40.51 52.72v105.86h-67.4V197.54zm-38.23-65.09c0 19.36-15.72 35.08-35.08 35.08-19.37 0-35.09-15.72-35.09-35.08 0-19.37 15.72-35.08 35.09-35.08 19.36 0 35.08 15.71 35.08 35.08zm-70.17 65.09h70.17v214.73H96.57V197.54z"
                />
              </svg>
            </a>
          </li>
          <li className="privacy">
            <a href="#">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                shapeRendering="geometricPrecision"
                textRendering="geometricPrecision"
                imageRendering="optimizeQuality"
                fillRule="evenodd"
                clipRule="evenodd"
                viewBox="0 0 121 150.26"
                height={"20px"}
                fill="#fff"
              >
                <path
                  fillRule="nonzero"
                  d="M2.892,56.036h8.959v-1.075V37.117c0-10.205,4.177-19.484,10.898-26.207v-0.009 C29.473,4.177,38.754,0,48.966,0C59.17,0,68.449,4.177,75.173,10.901l0.01,0.009c6.721,6.723,10.898,16.002,10.898,26.207v17.844 v1.075h7.136c1.59,0,2.892,1.302,2.892,2.891v61.062c0,1.589-1.302,2.891-2.892,2.891H2.892c-1.59,0-2.892-1.302-2.892-2.891 V58.927C0,57.338,1.302,56.036,2.892,56.036L2.892,56.036z M26.271,56.036h45.387v-1.075V36.911c0-6.24-2.554-11.917-6.662-16.03 l-0.005,0.004c-4.111-4.114-9.787-6.669-16.025-6.669c-6.241,0-11.917,2.554-16.033,6.665c-4.109,4.113-6.662,9.79-6.662,16.03 v18.051V56.036L26.271,56.036z M49.149,89.448l4.581,21.139l-12.557,0.053l3.685-21.423c-3.431-1.1-5.918-4.315-5.918-8.111 c0-4.701,3.81-8.511,8.513-8.511c4.698,0,8.511,3.81,8.511,8.511C55.964,85.226,53.036,88.663,49.149,89.448L49.149,89.448z"
                />
              </svg>
            </a>
          </li>
        </ul>
      </div> */}

      <footer className="absolute left-0 bottom-0 w-full">
        <div className="w-full flex">
          <ul className="list-none w-full align-items-center">
            <li className="text-center">
              <p className="font-bold text-color text-xs text-200 letter-spacing">
                © 2024 VAF. All Rights Reserved.
              </p>
            </li>
          </ul>
        </div>
      </footer>
      {showSpinner && <UploadingLoader text="Checking OCR..."></UploadingLoader>}
      {showAbstrpinner && <AiSpinner text="Abstracting..."></AiSpinner>}
      {pdfShowSpinner && <UploadingLoader></UploadingLoader>}
    </div>
  );
};

export default DocAssist;
