import { useEffect, useRef, useState } from "react";
import PrimeReactModule from "../../utils/context/PrimeReact";
import { RouterProps } from "../../utils/modals/Models.interface";
import { classNames } from "primereact/utils";
import image from "../../assets/Images/common/export/pdf.png";
import { BASE_URL, http } from "../../services/http.service";
import {
  FileUpload,
  FileUploadBeforeUploadEvent,
  FileUploadHeaderTemplateOptions,
  FileUploadUploadEvent,
  ItemTemplateOptions,
} from "primereact/fileupload";
import { sendFormDataRequest } from "../../services/http.service";
import { getAuthUser, removeAll } from "../../services/getLocalData";
import UploadingLoader from "../../utils/context/UploadingLoader";
import { Link, useNavigate } from "react-router-dom";
import { Menu } from "primereact/menu";
import { MenuItem } from "primereact/menuitem";
import ocr from "./../../assets/Images/common/cor1.png";
import tick from "./../../assets/Images/common/tick.jpg";
import userlogo from "./../../assets/Images/common/userprofile.png";
import { Avatar } from "primereact/avatar";
import "@react-pdf-viewer/core/lib/styles/index.css";

import "./../../styles/pdf.scss";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import { version } from "pdfjs-dist/legacy/build/pdf";
// { show, progressRef, prog,onShowSpinner }
const Dashboard = ({
  show,
  progressRef,
  prog,
  onShowSpinner,
  onShowPdfSpinner,
  getSummary,
}: any) => {
  const [layout, setLayout] = useState<any>("list");
  const [products, setProducts] = useState<any>();
  const [template, setTemplate] = useState<any>();
  const fileUploadRef = useRef<FileUpload>(null);
  const [isShowLoader, setShowLoader] = useState<boolean>(false);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [totalSize, setTotalSize] = useState<any>(0);
  const [files, setFile] = useState<File[] | any>([]);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  // const [showSpinner, setShowSpinner] = useState<boolean>(false);

  useEffect(() => {
    document.title = "Documents - CogniCraft";
    getApi();
    // getTemplate();
    // fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getApi = async () => {
    await http.get(`get/data/` + getAuthUser()?.userid).then((res: any) => {
      setProducts(res.data);
    });
  };

  // const getTemplate = async () => {
  //   await http.get(`get/template`).then((res: any) => {
  //     // setTemplate(res?.data);
  //     // setSelectedTemplate(res?.data[6]);
  //   });
  // };

  const listItem = (product: any) => {
    return (
      <div
        className="col-12 cursor-pointer"
        key={product.documentId}
        onClick={() =>
          navigate(
            "/project/document/" + product.documentId + "/" + product.templateId
          )
        }
      >
        <div
          className={classNames("grid justify-content-between p-3", {
            "border-top-1 surface-border": "",
          })}
        >
          <div className="col-2">
            <img
              title="View"
              className="w-4 sm:w-4rem xl:w-4rem block xl:block mx-auto border-round cursor-pointer"
              src={image}
              alt={product.name}
              onClick={(event) => {
                event.stopPropagation();
                pdfShow(product.documentId, product.documentName);
              }}
            />
          </div>
          <div className="col-3 flex" style={{ minHeight: "100%" }}>
            <div className="text-sm text-900 mt-2 text_Len flex align-items-center">
              <Link
                title={product.documentName}
                to={
                  "/project/document/" +
                  product.documentId +
                  "/" +
                  product.templateId
                }
                className="cursor-pointer underline"
              >
                {product.documentName}
              </Link>
              {/* <a href="" >{product.documentName}</a> */}
            </div>
          </div>
          <div className="col-3 flex" style={{ minHeight: "100%" }}>
            <div className="text-sm text-900 mt-2 flex align-items-center">
              {product.templateName}
            </div>
          </div>
          <div className="col-3">
            <div className="flex sm:flex-column gap-3 sm:gap-2">
              <div className="text-sm  text-900">
                Abstraction :{" "}
                <span className="font-normal">
                  {" "}
                  {product.isAbstracted === true
                    ? "Completed"
                    : "Not Completed"}
                </span>
              </div>
              <div className="text-sm text-900">
                Ocr :{" "}
                <span className="font-normal">
                  {" "}
                  {product.isOCRed === true ? "Verified" : "Not Verified"}
                </span>
              </div>
              <div className="text-sm text-900">
                Creation :{" "}
                <span className="font-normal">
                  {" "}
                  {product?.createdDate ? formatDate(product.createdDate) : ""}
                </span>
              </div>
            </div>
          </div>
          <div className="col-1 flex" style={{ minHeight: "100%" }}>
            <div className="text-sm text-900 mt-2 flex align-items-center">
              <PrimeReactModule.Button
                icon="pi pi-trash"
                className="p-button-rounded flex align-items-center text-red-600"
                raised
                text
                tooltip="Delete"
                tooltipOptions={{ position: "bottom" }}
                onClick={(event) => {
                  event.stopPropagation();
                  deleteDocument(product.documentId);
                }}
              ></PrimeReactModule.Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const deleteDocument = (fileTuneId: any) => {
    http.delete("delete/pdf/" + fileTuneId).then((res: any) => {
      if (res.code === 200) {
        show(res.message, res.data, "success");
        getApi();
      }
    });
  };
  // const deleteDocument = (documnetId: any) => {
  //   http.delete("delete/document/" + documnetId).then((res: any) => {
  //     if (res.code === 200) {
  //       show(res.message, res.data, "success");
  //       getApi();
  //     }
  //   });
  // };

  const initUrlinitUrl = (documentId: number) => {
    return BASE_URL + "pdf/" + documentId;
  };

  const pdfShow = async (documentId: any, documnetName: any) => {
    
    try {
      const response = await fetch(initUrlinitUrl(documentId));
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const blob = await response.blob();
      var blobs = new Blob([blob], { type: "application/pdf" });
      var blobURL = URL.createObjectURL(blobs);
      window.open(blobURL);
    } catch (error) {
      console.error("Error fetching or converting PDF:", error);
    }
  };

  const gridItem = (product: any) => {
    return (
      <div
        className="col-12 sm:col-6 lg:col-12 xl:col-4 p-2 cursor-pointer"
        key={product.documentId}
        onClick={() =>
          navigate(
            "/project/document/" + product.documentId + "/" + product.templateId
          )
        }
      >
        <div className="p-4 border-1 surface-border surface-card border-round">
          <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <div className="flex align-items-center gap-2">
              <span className="font-semibold">{product.category}</span>
            </div>
          </div>
          <div className="flex flex-column align-items-center gap-3 pt-3 pb-2">
            <img
              className="w-2 border-round cursor-pointer"
              src={image}
              alt={product.documentName}
              onClick={(event) => {
                event.stopPropagation();
                pdfShow(product.documentId, product.documentName);
              }}
            />
            <div className="text-md font-bold mt-2 text_Len">
              <Link
                title={product.documentName}
                to={
                  "/project/document/" +
                  product.documentId +
                  "/" +
                  product.templateId
                }
                className="cursor-pointer underline"
              >
                {product.documentName}
              </Link>
            </div>
            <div className="text-sm font-bold">{product.templateName}</div>
          </div>
          <div className="text-md font-bold mt-2">
            Abstraction :{" "}
            <span className="font-normal">
              {" "}
              {product.isAbstracted === true ? "Completed" : "Not Completed"}
            </span>
          </div>
          <div className="text-md font-bold mt-2">
            Ocr :{" "}
            <span className="font-normal">
              {" "}
              {product.isOCRed === true ? "Verified" : "Not Verified"}
            </span>
          </div>
          <div className="flex align-items-center justify-content-between pt-2">
            <span className="text-md font-semibold">
              {product?.createdDate ? formatDate(product.createdDate) : ""}
            </span>
            <PrimeReactModule.Button
              icon="pi pi-trash"
              className="p-button-rounded flex align-items-center text-red-600"
              raised
              text
              tooltip="Delete"
              tooltipOptions={{ position: "bottom" }}
              onClick={(event) => {
                event.stopPropagation();
                deleteDocument(product.documentId);
              }}
            ></PrimeReactModule.Button>
          </div>
        </div>
      </div>
    );
  };

  const itemTemplate = (product: any, layout: any) => {
    if (!product) {
      return;
    }

    if (layout === "list") return listItem(product);
    else if (layout === "grid") return gridItem(product);
  };

  const formatDate = (inputDate: any) => {
    const date = new Date(inputDate);
    // Extracting individual components
    const year = date.getFullYear().toString().slice(0, 4); // Use only the last two digits of the year
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding 1 because months are zero-indexed
    const day = date.getDate().toString().padStart(2, "0");

    // Creating the formatted date string
    const formattedDate = `${day}-${month}-${year}`;

    return formattedDate;
  };

  const header = () => {
    return (
      <div className="flex justify-content-end">
        <PrimeReactModule.DataViewLayoutOptions
          layout={layout}
          onChange={(e) => setLayout(e.value)}
        />
      </div>
    );
  };

  const onTemplateUpload = async (e: FileUploadBeforeUploadEvent) => {
    
    prog(50);
    onShowPdfSpinner(true);
    if (uploadedFiles[0].type === "application/pdf") {
      uploadedFiles.forEach((file: File) => {
        e.formData.append("files", file);
        e.formData.append("userId", getAuthUser()?.userid);
        // e.formData.append("templateId", selectTemplate.templateId);
      });
      await sendFormDataRequest("uploads/pdf", e.formData).then((res: any) => {
        if (res) {
          if (res.data.code === 201) {
            show(res.data.message, res.data.data, "success");
          }
          setUploadedFiles([]);
          setFile([]);
          setSelectedTemplate(null);
          prog(100);
          onShowPdfSpinner(false);
          getApi();
        } else {
          onShowPdfSpinner(false);
          prog(100);
        }
      });
    } else {
      setUploadedFiles([]);
      show("Required", "Upload Only Pdf File", "error");
    }
    // setTotalSize(_totalSize);
  };

  //file-upload start
  const onTemplateSelect: any = (e: FileUploadUploadEvent) => {
    let _totalSize: any = totalSize;
    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });
    const selected = e.files;
    setUploadedFiles([...uploadedFiles, ...selected]);
    setTotalSize(_totalSize);
    setFile(e.files);
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
    const { className, chooseButton, uploadButton } = options;
    return (
      <div
        className={className}
        style={{
          backgroundColor: "transparent",
          display: "flex",
          alignItems: "center",
          borderBottom: "2px solid #dee2e6",
        }}
      >
        {/* <div>
          <PrimeReactModule.Dropdown
            value={selectTemplate}
            disabled={files.length !== 0 ? true : false}
            className="w-12rem"
            filter
            name="templateId"
            options={template}
            optionLabel="templateName"
            placeholder="Select a Template"
            onChange={(e) => {
              setSelectedTemplate(e.value);
            }}
            showClear={true}
          />
        </div> */}
        <div>
          {chooseButton}
          {uploadButton}
        </div>
      </div>
    );
  };

  const onTemplateRemove = (file: File, callback: Function) => {
    setTotalSize(totalSize - file.size);
    callback();
    setFile([]);
  };

  const [selectTemplate, setSelectedTemplate] = useState<any>();

  const UploaditemTemplate = (inFile: object, props: ItemTemplateOptions) => {
    const file: any = inFile as File;
    return (
      <>
        <div className="grid align-items-center flex-wrap justify-content-around py-1 px-1 m-0">
          <div className="col-1 text-center">
            <i className="pi pi-file-pdf"></i>
          </div>
          <div className="col-4 text-left text-sm">{file.name}</div>
          <div className="col-1 text-left">
            {new Date().toLocaleDateString()}
          </div>
          <div className="col-2 text-center">
            <PrimeReactModule.Tag
              value={props.formatSize}
              severity="warning"
              className="px-3 text-xs py-2"
            />
          </div>
          <div className="col-1 text-center">
            <PrimeReactModule.Button
              type="button"
              icon="pi pi-times"
              className="p-button-outlined p-button-rounded p-button-danger ml-auto"
              onClick={() => onTemplateRemove(file, props.onRemove)}
            />
          </div>
        </div>
      </>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i
          className="pi pi-file-pdf mt-1"
          style={{
            fontSize: "2em",
            borderRadius: "50%",
            color: "var(--surface-d)",
          }}
        ></i>
        <span
          style={{ fontSize: "12px", color: "var(--text-color-secondary)" }}
          className="my-2"
        >
          Drag and Drop PDF Here
        </span>
      </div>
    );
  };

  const showSummary = (isShow: any, fileTuneId: any) => {
    
    getSummary(isShow, fileTuneId);
  };

  const chooseOptions = {
    icon: "pi pi-fw pi-plus",
    label: "Choose",
    className: "custom-choose-btn p-button-rounded ",
  };

  const uploadOptions = {
    icon: "pi pi-fw pi-upload",
    label: "Upload",
    className: "custom-upload-btn p-button-success p-button-rounded ",
  };

  const cancelOptions = {
    icon: "pi pi-fw pi-times",
    label: "Cancel",
    className: "custom-cancel-btn p-button-danger p-button-rounded ",
  };

  let items: MenuItem[] = [
    {
      template: (item, options) => {
        return (
          <div
            className={classNames(
              options.className,
              "w-full p-link flex align-items-center"
            )}
          >
            <p className="text-center w-full font-bold text-base">Documents</p>
          </div>
        );
      },
    },
    {
      label: "Templates",
      icon: "pi pi-fw pi-database",
      command: () => {
        navigate("/dashboard/template");
      },
    },
    {
      label: "User Manual",
      icon: "pi pi-fw pi-book",
      command: () => {
        setVisible(true);
      },
    },
    {
      label: "Play Area",
      icon: "pi pi-fw pi-comment",
      command: () => {
        navigate("/project/prompt/train");
      },
    },
    { separator: true },
    {
      command: () => {},
      template: (item, options) => {
        return (
          <button
            onClick={(e) => options.onClick(e)}
            className={classNames(
              options.className,
              "w-full p-link flex align-items-center"
            )}
          >
            <Avatar image={userlogo} className="mr-2" shape="circle" />
            <div className="flex flex-column align">
              <span className="font-bold">{getAuthUser()?.username}</span>
            </div>
          </button>
        );
      },
    },
    {
      label: "Logout",
      icon: "pi pi-fw pi-sign-out",
      command: () => {
        removeAll();
        navigate("/login");
      },
    },
  ];

  // const templateName = (option: any) => {
  //   return <>{option.templateName}</>;
  // };

  const checkOCR = (ocrdata: any) => {
    onShowSpinner(true);
    http.get("check/ocr/" + ocrdata.documentId).then((res: any) => {
      if (res) {
        onShowSpinner(false);
        getApi();
      }
      onShowSpinner(false);
    });
  };

  return (
    <>
      <div className="w-full pt-2 flex">
        <div className="w-12 px-2 fine-tune flex flex-column relative">
          <div className="flex flex-column overflow-y-auto overflow-x-hidden">
            {/* upload start */}
            <section className="section-prompt w-full mx-auto">
              <PrimeReactModule.FileUpload
                ref={fileUploadRef}
                name="demo[]"
                url="api/v1/upload/fine/tune/document"
                className="border-round"
                multiple={false}
                accept="application/pdf"
                onBeforeUpload={onTemplateUpload}
                onSelect={onTemplateSelect}
                onError={onTemplateClear}
                onClear={onTemplateClear}
                headerTemplate={headerTemplate}
                itemTemplate={UploaditemTemplate}
                emptyTemplate={emptyTemplate}
                chooseOptions={chooseOptions}
                uploadOptions={uploadOptions}
                cancelOptions={cancelOptions}
              />
            </section>
            {/* upload end */}
            <div>
              {/* upload start */}
              <PrimeReactModule.DataTable
                scrollable
                scrollHeight="calc(100vh - 350px)"
                className="mt-3"
                size="small"
                showGridlines
                paginator
                rows={25}
                responsiveLayout="scroll"
                rowsPerPageOptions={[5, 10, 25, 50]}
                value={products}
                editMode="row"
                dataKey="documentId"
                tableStyle={{ minWidth: "50rem" }}
                globalFilterFields={["documentName", "createdDate"]}
                emptyMessage="No Field's Found."
              >
                {/* <PrimeReactModule.Column
                  sortable
                  field="sNo"
                  header="Section No"
                  style={{ width: "20%" }}
                ></PrimeReactModule.Column> */}
                <PrimeReactModule.Column
                  sortable
                  field="docName"
                  header="Document Name"
                  body={(option) => {
                    return (
                      <div
                        className="flex"
                        onClick={(event) => {
                          pdfShow(option.documentId, option.docName);
                        }}
                      >
                        <a className="flex" style={{cursor:'pointer'}}>
                          {" "}
                          <img
                            title="View"
                            className="w-2 ml-3 sm:w-4rem xl:w-2rem block xl:block mr-2 border-round cursor-pointer"
                            src={image}
                            alt={option.name}
                          ></img>
                          <span className="ml-2 pt-2">{option.docName}</span>
                        </a>
                      </div>
                    );
                  }}
                  style={{ width: "20%" }}
                ></PrimeReactModule.Column>
                <PrimeReactModule.Column
                  sortable
                  field="createdDate"
                  body={(option) => {
                    return (
                      <>
                        {option?.createdDate
                          ? formatDate(option.createdDate)
                          : ""}
                      </>
                    );
                  }}
                  header="Created Date"
                  style={{ width: "20%" }}
                ></PrimeReactModule.Column>
                <PrimeReactModule.Column
                  header="OCR"
                  align={"center"}
                  body={(option) => {
                    return (
                      <>
                        {option.isOCR ? (
                          <img src={tick} width={"40px"} />
                        ) : (
                          <img
                            src={ocr}
                            width={"30px"}
                            onClick={() => checkOCR(option)}
                            style={{cursor:'pointer'}}
                          />
                        )}
                      </>
                    );
                  }}
                  className="text-center"
                  style={{ width: "10%" }}
                ></PrimeReactModule.Column>
                <PrimeReactModule.Column
                  header="Action"
                  align={"center"}
                  body={(option) => {
                    return (
                      <>
                        <PrimeReactModule.Button
                          icon="pi pi-trash"
                          className="p-button-rounded align-items-center text-red-600 mx-2 my-1"
                          raised
                          text
                          tooltip="Delete"
                          tooltipOptions={{ position: "bottom" }}
                          onClick={(event) => {
                            deleteDocument(option.documentId);
                          }}
                        ></PrimeReactModule.Button>
                      </>
                    );
                  }}
                  className="text-center"
                  style={{ width: "10%" }}
                ></PrimeReactModule.Column>
                <PrimeReactModule.Column
                  header="Craft"
                  align={"center"}
                  body={(option) => {
                    return (
                      <>
                        <PrimeReactModule.Button
                          icon="pi pi-eye"
                          className="p-button-rounded align-items-center mx-2 my-1"
                          raised
                          text
                          disabled={!option.isOCR}
                          tooltip="Summary"
                          tooltipOptions={{ position: "bottom" }}
                          onClick={(event) => {
                            showSummary(false, option.documentId);
                            // deleteDocument(option.fineTuneId);
                          }}
                        ></PrimeReactModule.Button>
                      </>
                    );
                  }}
                  className="text-center"
                  style={{ width: "10%" }}
                ></PrimeReactModule.Column>
              </PrimeReactModule.DataTable>
              {/* upload end */}
            </div>
          </div>
        </div>
      </div>

      <PrimeReactModule.Dialog
        header="User Manual"
        visible={visible}
        style={{ width: "75vw" }}
        onHide={() => setVisible(false)}
        maximizable
      >
        <Worker
          workerUrl={`https://unpkg.com/pdfjs-dist@${version}/build/pdf.worker.min.js`}
        >
          <div className="custom-pdf-editor__view">
            <Viewer
              fileUrl={require("./../../assets/userManual/Introduction.pdf")}
            />
          </div>
        </Worker>
      </PrimeReactModule.Dialog>
      {/* {showSpinner && <UploadingLoader text="Check OCR..."></UploadingLoader>} */}
    </>
  );
};
export default Dashboard;
