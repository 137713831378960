import { useEffect, useState } from "react";
import { http } from "../../services/http.service";
import PrimeReactModule from "../../utils/context/PrimeReact";
import { Template, Form, Field, Prompt } from "./MastersTab";
import { Header } from "../../layout";

const Masters = ({ show }: any) => {
  const [, setTemplate] = useState<any>();

  useEffect(() => {
    getTemplateDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTemplateDetails = async () => {
    await http.get("get/template").then((res: any) => {
      if (res) {
        setTemplate(res?.data);
      }
    });
  };

  return (
    <>
      {/* <Header></Header> */}
      <div>
      <div className="card master_tab">
        <PrimeReactModule.TabView>
          <PrimeReactModule.TabPanel header="Template">
            <Template show={show}></Template>
          </PrimeReactModule.TabPanel>
          <PrimeReactModule.TabPanel header="Section">
            <Form show={show}></Form>
          </PrimeReactModule.TabPanel>
          <PrimeReactModule.TabPanel header="Clause">
            <Field show={show}></Field>
          </PrimeReactModule.TabPanel>
          <PrimeReactModule.TabPanel header="Prompt">
            <Prompt show={show}></Prompt>
          </PrimeReactModule.TabPanel>
        </PrimeReactModule.TabView>
      </div>
      </div>
    </>
  );
};

export default Masters;
