
const Field = ({ label, baseindex, no, id }: any) => {
    return (
        <>
            <label id={`accordionTab_${id}`}
                className="text-sm col-12 relative font-bold font-bold inline border-round line-height-4 label-head"
            >
                {baseindex + 1}.{no + 1}. {label} 
            </label>
        </>
    )
}

export default Field;