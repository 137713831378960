import React from "react";
import { Field, Values } from "./FieldValueRenderer"

const DataField: React.FC<any> = ({ data, setDataSet, prog, show, onTriggerEvent, baseindex, permission }) => {
    return (
        <>
            <div className="p-1">
                {data?.field?.map((item: any, indexing: any) => (
                    <div className="field grid" key={indexing}>
                        <Field label={item.label} no={indexing} baseindex={baseindex} id={item.fieldId}></Field>
                        <div className="col-12 pl-4">
                            <div className="grid">
                                <Values onTriggerEvent={onTriggerEvent} show={show} prog={prog} item={item} pageIndex={item.pageIndex} keywords={item.keyWord} setDataSet={setDataSet} val={item?.value} data={data} permission={permission}></Values>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}
export default DataField;